<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isLoading" class="col-12">
        <dl class="row">
          <dt class="col-lg-2">Account Name</dt>
          <dd class="col-lg-4" :title="account.accountName">{{account.accountName}}</dd>  
          <dt class="col-lg-2">Account ID</dt>
          <dd class="col-lg-4" :title="account.accountId">{{account.accountId}}</dd>
          <dt class="col-lg-2">Service ID</dt>
          <dd class="col-lg-4" :title="parseComponentIdValue(account.components,false)">
            {{parseComponentIdValue(account.components)}}
            <div>
              <button class="btn-link" style="padding: 0" v-if="showMoreComponentsId!==null" @click="()=>showMoreComponentsId=!showMoreComponentsId">{{showMoreComponentsId ? "show more": "show less"}}</button>
            </div>
          </dd>
          <dt class="col-lg-2">Service Name</dt>
          <dd class="col-lg-4" :title="parseComponentValue(account.components,false)">
            {{parseComponentValue(account.components)}}
            <div>
              <button class="btn-link" style="padding: 0" v-if="showMoreComponents!==null" @click="()=>showMoreComponents=!showMoreComponents">{{showMoreComponents ? "show more": "show less"}}</button>
            </div>
          </dd>
<!--          <dt class="col-lg-2">Monikers</dt>-->
<!--          <dd class="col-lg-4" :title="account.monikers">-->
<!--            {{parseMonikerValue(account.monikers)}}-->
<!--            <div>-->
<!--              <button class="btn-link" style="padding: 0" v-if="showMoreMonikers!==null" @click="()=>showMoreMonikers=!showMoreMonikers">{{showMoreMonikers ? "show more": "show less"}}</button>-->
<!--            </div>-->
<!--          </dd>-->
          <dt class="col-lg-2">Cost Center ID</dt>
          <dd class="col-lg-4" :title="account.costCenterId">{{!account.costCenterId?"-":account.costCenterId}}</dd>
        </dl>
        <div class="row">
          <div class="col-md-12">
            <button class="btn tab-btn" @click="switchOwnersView">Owners</button>
            <button class="btn tab-btn" @click="switchViewersView">Viewers</button>
<!--            <button class="btn tab-btn" @click="switchOwnersView">Approvers</button>-->
            <v-client-table  v-model="this.tableData" :columns="columns" :options="options">
            </v-client-table>
          </div>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="loading-container" >
          <loading-spinner  />
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import api from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import LoadingSpinner from "@/components/LoadingSpinner";
import * as C from "../constants.js";

export default {
  name: "accountDetails",
  components: {
    LoadingSpinner
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
  },
  props: {
    data: {},
  },
  data: function () {
    return {
      C,
      isLoading: true,
      showMoreMonikers: null,
      showMoreComponents: null,
      showMoreComponentsId: null,
      maxValueLength : 43 * 2,
      account : {},
      tableData: {},
      columns: ["displayName", "email", "title", "organization"],
      options: {
        skin: "table users-table table-striped",
        headings: {
          displayName: "Name",
          email: "Email"
        },
        uniqueKey: "email",
        sortable: ["displayName", "email"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No users to display",
        },
        headingsTooltips: {
          displayName : "Click to sort. Shift+Click to multi-sort.",
          email : "Click to sort. Shift+Click to multi-sort."
        },
        perPage: 10,
        perPageValues: [10]
      },
    };
  },
  async created() {
    this.isLoading = true
    try {
      let detailsResponse = await api.getAccountDetails(this.data.accountId)
      this.account = detailsResponse.data.data

      if(this.parseComponentValue(this.account.components, false).length > this.maxValueLength){this.showMoreComponents = true}
      if(this.parseComponentIdValue(this.account.components, false).length > this.maxValueLength){this.showMoreComponentsId = true}
      if(this.parseMonikerValue(this.account.monikers, false).length > this.maxValueLength){this.showMoreMonikers = true}
    } catch (err) {
      this.addAlert(
          util.createAlert(0,
            "Error loading account details. Please try again or contact support.",
            C.ALERT_CONTEXT.DANGER
          )
        );
    }
    if (!this.account.owners) {
      this.account.owners = []
    }
    if (!this.account.viewers) {
      this.account.viewers = []
    }

    this.isLoading = false;
    this.tableData = this.account.owners
  },
  methods: {
    ...mapMutations(["setAccounts"]),
    ...mapActions(["addAlert"]),
    closeDetailView(){
      this.$router.push({ path: "/" });
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    parseComponentValue(components, trim){
      let componentString = "-";
      let trimToggle = trim ? trim : this.showMoreComponents
      if(components && components.length>0){
        componentString = components.map((component)=> component.componentName).join(", ")
        var trimmedString = componentString.length > this.maxValueLength ? componentString.substring(0, this.maxValueLength - 3) + "…" : componentString;
      }
      return trimToggle ? trimmedString : componentString
    },
    parseComponentIdValue(components, trim){
      let componentString = "-";
      let trimToggle = trim ? trim : this.showMoreComponentsId
      if(components && components.length>0){
        componentString = components.map((component)=> component.componentId).join(", ")
        var trimmedString = componentString.length > this.maxValueLength ? componentString.substring(0, this.maxValueLength - 3) + "…" : componentString;
      }
      return trimToggle ? trimmedString : componentString
    },
    parseMonikerValue(monikers, trim){
      let monikerString = "-";
      let trimToggle = trim ? trim : this.showMoreMonikers
      if(monikers && monikers.length>0){
        monikerString = monikers.join(", ")
        var trimmedString = monikerString.length > this.maxValueLength ? monikerString.substring(0, this.maxValueLength - 3) + "…" : monikerString;
      }
      return trimToggle ? trimmedString : monikerString
    },
    switchOwnersView(){
      this.tableData = this.account.owners
    },
    switchViewersView(){
      this.tableData = this.account.viewers
    }
  },
};
</script>
<style scoped>
.loading-container {
  margin-top: 25px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}

.access-link {
  font-weight: bold;
}

.tab-btn{
  border-width: 0.25px;
  border-color: #dee2e6;
  border-bottom-width: 0;
  border-radius: 0;
}

</style>
