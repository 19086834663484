<template>
  <span v-if="props.sortable" :class="this.props.class.replace('float-right', 'sort-icon')"></span>
</template>

<script>
export default {
  name: "SortControl",
  props: ["props"]
};
</script>
<style scoped>
.sort-icon {
  margin-left: 15px;
}
</style>