<template>
  <div class="float-right">
    <paginate
      v-model="page"
      :page-count="Math.ceil(props.records / props.perPage)"
      :click-handler="setPage"
      :prev-text="''"
      :next-text="''"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
      :prev-class="'page-item'"
      :prev-link-class="'page-link-icon fa fa-caret-left'"
      :next-class="'page-item'"
      :next-link-class="'page-link-icon fa fa-caret-right'"
    ></paginate>
    <strong class="entries-text">{{getCurrentRecordCount() }} of {{props.records}} entries</strong>
  </div>
</template>

<script>
//import Pagination from "vue-pagination-2";
import Paginate from "vuejs-paginate";
import { ClientTable, Event } from "vue-tables-2";
import { mapState , mapMutations} from 'vuex';

export default {
  name: "Paginator",
  components: {
    Paginate,
  },
  data: function () {
    return {
      page: 1
    };
  },
  computed: mapState(['tableReset']),
  props: ["props"],
   watch: {
    tableReset(newValue, oldValue) {
      if (newValue != 0 && this.page != 1) {
        this.setPage(1)
      }
    }
  },
  methods :{
    ...mapMutations(["setTableReset"]),
    getCurrentRecordCount() {
      let count = this.props.page * this.props.perPage
      if (count > this.props.records) {
        count = this.props.records
      }
      return count
    },
    setPage(page) {
      this.props.setPage(page)
      this.page = page
      this.setTableReset(0)
    },
 
  }
};
</script>
<style>
.entries-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

</style>