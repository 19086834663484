<template>
  <div class="container">
    <div class="row content-heading" >
      <div class="col-md-6">
        <div class="btn-wrapper bordered">
          <button class="btn flat-btn blue-btn" :class="{'selected' : displayMyAccounts}" @click="showMyAccounts">
            My accounts
          </button>
        </div>
        <div class="btn-wrapper">
          <button class="btn flat-btn blue-btn" :class="{'selected' : displayAllCorporateAccounts}" @click="showAllCorporateAccounts">
            All accounts
          </button>
        </div>
      </div>
    </div>
    <my-accounts-table v-if="displayMyAccounts"></my-accounts-table>
    <all-corporate-accounts-table v-if="displayAllCorporateAccounts"></all-corporate-accounts-table>

  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MyAccountsTable from "@/components/MyAccountsTable";
import AllCorporateAccountsTable from "@/components/AllCorporateAccountsTable";
import * as C from "../constants.js";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Dashboard",
  components: {
    LoadingSpinner,
    MyAccountsTable,
    AllCorporateAccountsTable
  },
  computed: {
    ...mapGetters([
      "getAllCorporateAccounts",
      "getAllCorporateAccountsLoading"]),
    viewFilter() {
      return this.$route.query.view;
    },
  },
  data: function () {
    return {
      C,
      displayMyAccounts: true,
      displayAllCorporateAccounts: false
    };
  },
  created() {
    if (this.viewFilter == "my-accounts") {
      this.showMyAccounts()
    } else if (this.viewFilter == "all-corporate-accounts") {
      this.showAllCorporateAccounts()
    }
  },
  methods: {
    ...mapActions(["loadAllAccounts"]),
    showMyAccounts() {
      this.displayMyAccounts = true
      this.displayAllCorporateAccounts = false
      if (this.viewFilter != "my-accounts") {
        this.$router.push({ path: '?view=my-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-my-accounts`); 
      }
    },
    async showAllCorporateAccounts() {
      this.displayAllCorporateAccounts = true
      this.displayMyAccounts = false

      // Load All Accounts on click and if data not already set in store
      if(this.getAllCorporateAccounts.length === 0) {
        await this.loadAllAccounts()
      }

      if (this.viewFilter != "all-corporate-accounts") {
        this.$router.push({ path: '?view=all-corporate-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-all-corporate-accounts`); 
      }
    }
  },
};
</script>
<style scoped>
.content-heading {
  margin-top: 10px;
  min-height: 30px;
}
.content-heading .btn-wrapper {
  display: inline-block;
  margin-right: 10px;
}
.content-heading .btn-wrapper.bordered {
  display: inline-block;
  margin-right: 10px;
  border-right: 2px solid #929292;
}

.content-heading .btn{
  margin-right: 10px;
  border-radius: 15px;
}
.account-help-text {
  float: right;
  font-size: 12px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
    .account-help-text {
        float: none;        
    }
    .help-text-wrapper{
      text-align: center;
    }
}   
@media (max-width: 400px) {
  .content-heading .btn-wrapper .btn  {
    min-width:200px;
    margin-top:5px;
  }
  .content-heading .btn-wrapper.bordered  {
    border: none;
  }
}
</style>
