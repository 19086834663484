<template>
  <div class="container">
    <div class="row content-heading" >
      <div class="col-12">
        <h3>Personalize your dashboard experience</h3>
      <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b class="section-title">Dashboard settings</b>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Default dashboard view</label>
          <select class="form-control">
            <option>- default -</option>
            <option>Corporate dashboard</option>
            <option>Commercial dashboard</option>
            <option>FedRAMP dashboard</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b class="section-title">Table settings</b>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Default table view</label>
          <select class="form-control">
            <option>My accounts</option>
            <option>All accounts</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Default rows per page</label>
          <select class="form-control">
            <option>10</option>
            <option>25</option>
            <option>50</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Default column sorting</label>
          <select class="form-control">
            <option>Favorites</option>
            <option>Name Ascending</option>
            <option>Name Descending</option>
            <option>Account ID Ascending</option>
            <option>Account ID Descending</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import * as C from "../constants.js";

export default {
  name: "Personalize",
  components: {
    LoadingSpinner,
  },
  computed: {
    viewFilter() {
      return this.$route.query.view;
    },
  },
  data: function () {
    return {
      C,
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>
<style scoped>
.section-title{
  margin-bottom: 15px;
  font-size: 16px;
}
.content-heading {
  margin-top: 10px;
  min-height: 30px;
}
.content-heading .btn-wrapper {
  display: inline-block;
  margin-right: 10px;
}
.content-heading .btn-wrapper.bordered {
  display: inline-block;
  margin-right: 10px;
  border-right: 2px solid #929292;
}

.content-heading .btn{
  margin-right: 10px;

}
.account-help-text {
  float: right;
  font-size: 12px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
    .account-help-text {
        float: none;        
    }
    .help-text-wrapper{
      text-align: center;
    }
}   
@media (max-width: 400px) {
  .content-heading .btn-wrapper .btn  {
    min-width:200px;
    margin-top:5px;
  }
  .content-heading .btn-wrapper.bordered  {
    border: none;
  }
}
</style>
