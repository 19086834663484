<template>
  <div>
    <div class="row">
      <div class="col-12">        
        <div class="accounts-table-container">
          <v-client-table class="table table-hover" v-model="rows" :columns="columns" :options="options" ref="accountTable">
            <button
              slot="favorite"
              slot-scope="{row, update}"
              class="fav-btn"
              :class="row.favorite?'fas fa-star':'far fa-star'"
              @click="setFavorite(row); update(row.favorite)"
            ></button>
            <span slot="accountCustomName" slot-scope="props">
              <button title="Open account details" class="btn-link" @click="toggleAccountDetails(props.row)">
                <div class="account-text">
                  <div class="child-toggler-icon">
                    <i class="fas fa-angle-down" v-if="detailsDisplayed.includes(props.row.accountId)" ></i> 
                    <i class="fas fa-angle-right" v-else></i> 
                  </div>
                  {{props.row.accountCustomName.toLowerCase() || "-"}}
                </div>
              </button>
            </span>
            <span slot="environment" slot-scope="props">
              <p>{{props.row.environment.toUpperCase() }}</p>
            </span>
            <span slot="roles" slot-scope="props">
              <p class="available-roles" :title="parseRole(props.row)">{{parseRole(props.row, true)}}</p>
            </span>
            <span slot="action" slot-scope="props">
              <!-- <button class="access-link" @click="accessActionClick(props.row)"  v-on:click.middle="accessActionClick(props.row)"
                :href="parseLoginUrl(props.row)">
                  Launch
              </button> -->
              <button title="Launch (prompt)" class="btn-link" @click="showAccessAccountModal(props.row)">
                Launch
              </button>
              <em v-if="getHasAdminRole && !hasValidAppId(props.row)" class="fa fa-exclamation app-id-notice" 
                :title="'Invalid App ID ('+props.row.azureAppId+')'"></em>
            </span>
            
            <template v-slot:child_row="props">
              <account-details :data="props.row"></account-details>
            </template>
          </v-client-table>
        </div>
      </div>
<!--      <div class="row" >-->
<!--        <div class="col-12">-->
<!--          <strong v-if="getCacheDataDate">-->
<!--            <em class="cache-info-text" >Viewing My Accounts data from {{parseDate(getCacheDataDate)}}</em>-->
<!--            <button-->
<!--                title="Reload accounts"-->
<!--                class="sync-btn fas fa-sync-alt"              -->
<!--                @click="reloadAccounts()"-->
<!--              ></button>-->
<!--          </strong>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <confirm-access-account-modal
      v-if="displayAccessAccountModal"
      :account="accessAccount"
      @close="closeAccessAccountModal"
    />
  </div>
</template>

<script>
import api from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import LoadingSpinner from "@/components/LoadingSpinner";
import AccountDetails from "@/components/AccountDetailsV2.vue";
import * as C from "../constants.js";
import ConfirmAccessAccountModal from '@/components/modal/ConfirmAccessAccountModal.vue'

export default {
  name: "myFedrampAccountsTable",
  components: {
    LoadingSpinner,
    AccountDetails,
    ConfirmAccessAccountModal
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getFedrampAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
    rows: {
      get: function () {
        return this.getFedrampAccounts;
      },
      set: function (newValue) {
        this.setFedrampAccounts(newValue);
      },
    }
  },
  data: function () {
    return {
      C,
      accountsFiltered: true,
      columns: ["favorite", "accountCustomName", "environment",  "accountId", "roles", "action"],
      options: {
        customFilters: [
          {
            name: "search",
            callback: function (row, query) {
              let q = query.toLowerCase();
              let name = row.accountCustomName.toLowerCase();
              let id = row.accountId.toLowerCase();
              return name.includes(q) || id.includes(q);
            },
          },
        ],
        skin: "table",
        editableColumns: ["favorite"],
        headings: {
          favorite: "*",
          accountCustomName: "Name",
          environment: "Environment",
          accountId: "Account ID",
          roles: "Active roles",
          action: "Actions",
        },
        uniqueKey: "accountId",
        sortable: ["favorite", "accountCustomName", "accountId", "environment"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No FedRAMP accounts to display",
        },
        headingsTooltips: {
          favorite : "Click to sort. Shift+Click to multi-sort.",
          accountId : "Click to sort. Shift+Click to multi-sort.",
          accountCustomName : "Click to sort. Shift+Click to multi-sort."
        },
        perPageValues: [10,25,50],
        showChildRowToggler: false
      },
      isLoading: false,
      detailsDisplayed:[],
      displayAccessAccountModal: false,
      accessAccount: null
    };
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["setFedrampAccounts"]),
    ...mapActions(["addAlert", "loadAccounts"]),
        async setFavorite(val) {
      val.favorite = !val.favorite;
      try {
        if (val.favorite) {
          await api.saveMyFavorite({
            accountId: val.accountId,
          });
          this.trackEvent(C.ACTION_TYPES.ADD_FAVORITE, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        } else {
          await api.deleteMyFavorite(val.accountId);
          this.trackEvent(C.ACTION_TYPES.REMOVE_FAVORITE, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        }
      } catch (err) {
        val.favorite = !val.favorite;
        console.error("Error saving favorite", err);
        this.addAlert(
          util.createAlert(0,
            "Error modifying favorite. Please try again or contact support.",
            C.ALERT_CONTEXT.DANGER
          )
        );
      }
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    accessActionClick(item) {      
      this.trackEvent(C.ACTION_TYPES.ACCESS_LINK, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${item.accountId} name=${item.accountCustomName}`);
    },   
    parseRole(account, trim) {
      return util.parseRole(account,trim)
    },
    
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    async reloadAccounts() {
      await this.loadAccounts()
    },
    async toggleAccountDetails(row) {
      
      if (this.detailsDisplayed.includes(row.accountId)) {
        this.detailsDisplayed.splice(this.detailsDisplayed.indexOf(row.accountId), 1)
        this.trackEvent(C.ACTION_TYPES.HIDE_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      } else {
        this.detailsDisplayed.push(row.accountId)
        this.trackEvent(C.ACTION_TYPES.SHOW_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      }

      this.$refs.accountTable.toggleChildRow(row.accountId);
    },
    showAccessAccountModal(item) {   
      this.trackEvent(C.ACTION_TYPES.ACCESS_FEDRAMP_MODAL_LINK, 
        this.$router.currentRoute.path,  
        `account_id=${item.accountId} name=${item.accountCustomName}`);
      this.displayAccessAccountModal = true
      this.accessAccount = item
    },
    closeAccessAccountModal() {
      this.displayAccessAccountModal = false
      this.accessAccount = null
    },
  },
};
</script>
<style scoped>
.account-text{
  color:#5a5a5a;
  display:inline-flex;
}
.child-toggler-icon{
  font-size: 14px;
  margin-right: 8px;
  color: #5f60ff;
}
.cache-info-text {
  font-size: 14px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.accounts-table-container {
  margin-top: 5px;
  font-size: 14px;
}
.access-link {
  font-weight: bold;
}
.app-id-notice {
  color: red;
  font-size: 14px;
}
.sync-btn {
  border: none;
  background: transparent;
  color: #5f60ff;
  outline: none;
  margin-left: 5px;
  font-size: 14px;
}
.sync-btn:hover {
  color: #0056b3;
}

</style>
