import * as CONSTANTS from '../constants.js'
import api from "@/api";
import moment from 'moment'

export default {
  createAlert: function (code, msg, contextClass) {
    
    if (!contextClass) {
      contextClass = CONSTANTS.ALERT_CONTEXT.DANGER
    }
    return {
      code: code,
      msg: msg,
      contextClass: contextClass,
      active: true
    }
  },

  stringIsBlank(str) {
    return (!str || /^\s*$/.test(str));
  },

  orderSortComparator (a, b) {
    if (a.order < b.order) { 
      return -1 
    }
    if (a.order > b.order) { 
      return 1 
    }
    return 0
  },

  truncate(text,stop,clamp) {
    if (!text) {
      return text
    }
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
  },

  parseDate(datetime) {
    let time = moment(datetime)
    return time.format("LLL");
  },
  parseDateTime(datetime, format = "LLL") {
    let time = moment(datetime)
    return time.format(format);
  },
  parseRelativeTime(datetime){
    let time = moment(datetime)
    return time.startOf('seconds').fromNow()
  },
  timestampToDate(timestamp) {
    let time = moment.unix(timestamp);
    return time.format("LLL");
  },

  hasValidAppId(item) {
    return item.azureAppId && item.azureAppId != "Unknown"
  },

  getToday() {
    return moment().toISOString();
  },

  accountNameSort(a,b) {
    var nameA = a.accountCustomName.toLowerCase(); 
    var nameB = b.accountCustomName.toLowerCase(); 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  },

  async saveUserAction(type, view, reference) {
    let actionData = {
      type: type,
      view: view,
      reference: reference
    }
    api.saveUserAction(actionData).then(ele =>{
      //console.log("Action save success", actionData)
    }).catch(err => {
      console.error("Actions API POST error",actionData, err)
    })
  },

  parseLoginUrl(item, upn) {    
    if (upn.indexOf("@adskeng") === -1 && upn.indexOf("@autodesk") === -1) {
      upn = null
    }
    let loginHint = ""
    if (upn) {
      loginHint = `&login_hint=${upn}&prompt=none`
    } 
    // else {
    //   loginHint = "&prompt=login"
    // }
    
    return encodeURI(`${CONSTANTS.LOGIN_URL}/${item.azureAppId}?tenantId=${item.azureAppData[0].tenantId}${loginHint}`)
  },

  parseRole(account, trim) {
    let resp = account.azureAppData.map( ele =>{
      if (ele.displayName === CONSTANTS.GLOBAL_ADMINS_ROLE) {
        return "Global-Admins"
      }
      if (ele.displayName === CONSTANTS.NETWORK_ADMINS_ROLE) {
        return "Network-Admins"
      }
      if (ele.displayName === CONSTANTS.SECURITY_AUDIT_ROLE) {
        return "Security-Audit"
      }

      let nameArr = ele.displayName.split("-")
      nameArr.splice(0,3)
      return nameArr.join("-")
    })
    if (trim && resp.length > 3) {
      resp = resp.slice(0, 3)
      resp.push("...")
    }
    if(resp.length === 0){
      resp.push("None")
    }
    return resp.join(", ")
  },

  favoritesSortAccounts(accounts, favorites) {

    for (let account of accounts) {
      let favorite = favorites.find( fav =>{
        return fav.accountId == account.accountId
      })
      if (favorite) {
        account.favorite = true
      } else {
        account.favorite = false
      }
    }
    accounts.sort((a,b) =>{
      return (a.favorite === b.favorite) ? 0 : a.favorite? -1 : 1
    })

  },

  filterAccounts(accounts, profile) {
    return accounts.filter( ele =>{
      return ele.profile == profile
    })
  },

  generateCommercialUserId(user) {
    let resp = ""
    try {
      // First 6 letters of last name followed by first letter of first name 
      let prefix = user.lastName.toLowerCase().slice(0,6)
      prefix += user.firstName[0].toLowerCase()

      let uId = user.employeeId
      uId = uId.substring(uId.length-3,uId.length)
      resp = prefix + uId + "@adskeng.net"
    } catch (err) {
      console.log(err)
    }
    return resp
  }
}
