<template>
  <div class="container">
    <div class="row content-heading" >
      <div class="col-sm-4">
         <div class="btn-wrapper">
          <button class="btn flat-btn blue-btn" :class="{'selected' : displayMyAccounts}" @click="showMyAccounts">
            My accounts
          </button>
        </div>
<!--        <div class="btn-wrapper">-->
<!--          <button class="btn flat-btn blue-btn" :class="{'selected' : displayAllAccounts}" @click="showAllAccounts">-->
<!--            All accounts-->
<!--          </button>-->
<!--        </div> -->
      </div>
<!--      <div class="col-sm-12">-->
<!--        <p class="help-text-wrapper">-->
<!--        <em class="account-help-text">-->
<!--          To access AWS Console for a specific AWS account, click on “Launch” in the list below.-->
<!--          <br>-->
<!--          To request access to Commercial AWS Accounts/Business Services follow these links:-->
<!--          <ul class="mb-0">-->
<!--            <li>Request access to <strong><a target="_blank" :href="'https://myaccess.microsoft.com/@autodesk.onmicrosoft.com?login_hint='+getAppUser.email">Dev</a></strong> (use your autodesk.com user)</li>-->
<!--            <li>Request access to <strong><a target="_blank" :href="'https://myaccess.microsoft.com/@adskengpp.onmicrosoft.com?login_hint='+nonDevLoginHint">Stage</a></strong> (use your adskeng.net user)</li>-->
<!--            <li>Request access to <strong><a target="_blank" :href="'https://myaccess.microsoft.com/@adskeng.onmicrosoft.com?login_hint='+nonDevLoginHint">Prod</a></strong> (use your adskeng.net user)</li>-->
<!--          </ul>-->
<!--          Autodesk Cloud Products internal engineering accounts (adskeng.net) can be requested <strong><a target="_blank" href="https://employeehub.autodesk.com/apps/account-management/"> here</a></strong>.-->
<!--          <br>-->
<!--          Multi-tenant / shared AWS accounts: look up the associated Business Services for these accounts in this <strong><a target="_blank" href="https://share.autodesk.com/:x:/s/productivity.services.application.engineering/EbdXzE1FNqtBqBBAP2e14YgBQ4sCuGA3MarJLQc0w-U3UQ?e=bhu9Iz">XLS file</a></strong> and request the associated Access Package (Dev, Stage, Prod links above).-->
<!--          <br>-->
<!--          For help, please check the -->
<!--          <strong><a target="_blank" href="https://employeehub.autodesk.com/IT/articles/fff1c7f51bdef4501e13a931604bcb32"> FAQ </a></strong>-->
<!--          or contact support on Slack:-->
<!--          <strong><a target="_blank" href="https://autodesk.slack.com/archives/CDYEFBL9L"> #adsk-cloud-support</a></strong>.-->
<!--          <br>-->
<!--        </em></p>-->
<!--      </div>-->
      <div class="offset-6 col-2 d-flex flex-column justify-content-end align-items-end">
        <button class="btn flat-btn blue-btn" style="border-radius: 8px" @click="showCommercialRequestAccessModal()">Request Access</button>
      </div>
    </div>
    <my-commercial-accounts-table-v2 v-if="displayMyAccounts"></my-commercial-accounts-table-v2>
    <all-commercial-accounts-table v-if="displayAllAccounts"></all-commercial-accounts-table>
    <transition name="request-access-modal">
      <commercial-request-access-modal
          v-if="displayCommercialRequestAccessModal"
          :non-dev-login-hint="nonDevLoginHint"
          @close="closeCommercialRequestAccessModal"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import util from '@/util'
import MyCommercialAccountsTableV2 from "@/components/MyCommercialAccountsTableV2";
import AllCommercialAccountsTable from "@/components/AllCommercialAccountsTable";
import * as C from "../constants.js";
import CommercialRequestAccessModal from "@/components/modal/CommercialRequestAccessModal.vue";

export default {
  name: "CommercialDashboard",
  components: {
    CommercialRequestAccessModal,
    MyCommercialAccountsTableV2,
    AllCommercialAccountsTable
  },
  computed: {
    viewFilter() {
      return this.$route.query.view;
    },
    ...mapGetters(["getAppUser", "getCommercialAccounts"]),
  },
  data: function () {
    return {
      C,
      displayMyAccounts: true,
      displayAllAccounts: false,
      nonDevLoginHint: "",
      displayCommercialRequestAccessModal: false
    };
  },
  created() {
    if (this.viewFilter == "my-accounts") {
      this.showMyAccounts()
    } else if (this.viewFilter == "all-accounts") {
      this.showAllAccounts()
    }

    let nonDevAccount = this.getCommercialAccounts.find(account => account.environment == "prd" || account.environment == "stg")
    if (nonDevAccount && nonDevAccount.azureAppData.length > 0 && nonDevAccount.azureAppData.some(appData => appData.upn)) {
      this.nonDevLoginHint = nonDevAccount.azureAppData.find(appData => appData.upn).upn
    }
    else {
      this.nonDevLoginHint = this.getCommercialId()
    }
  },
  methods: {
    showMyAccounts() {
      this.displayMyAccounts = true
      this.displayAllAccounts = false
      if (this.viewFilter != "my-accounts") {
        this.$router.push({ path: '?view=my-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-my-accounts`); 
      }
    },
    showAllAccounts() {
      this.displayAllAccounts = true
      this.displayMyAccounts = false
      if (this.viewFilter != "all-accounts") {
        this.$router.push({ path: '?view=all-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-all-accounts`); 
      }
    },
    getCommercialId() {
      return util.generateCommercialUserId(this.getAppUser)
    },
    showCommercialRequestAccessModal() {
      this.trackEvent(C.ACTION_TYPES.COMMERCIAL_ACCESS_REQUEST_LINK, this.$router.currentRoute.path);
      this.displayCommercialRequestAccessModal = true
    },
    closeCommercialRequestAccessModal() {
      this.displayCommercialRequestAccessModal = false
    },
  }
};
</script>
<style scoped>
.content-heading {
  margin-top: 10px;
  min-height: 30px;
}
.content-heading .btn-wrapper {
  display: inline-block;
  margin-right: 10px;
}
.content-heading .btn-wrapper.bordered {
  display: inline-block;
  margin-right: 10px;
  border-right: 2px solid #929292;
}
.content-heading .btn{
  margin-right: 10px;
  border-radius: 15px;
}
.account-help-text {
  float: left;
  font-size: 12px;
  margin-bottom: 0;
}

.request-access-modal-enter-active {
  transition: all .3s ease-in;
}
.request-access-modal-leave-active {
  transition: all .3s ease-out;
}
.request-access-modal-enter, .request-access-modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}

@media (max-width: 576px) {
    .account-help-text {
        float: none;        
    }
    .help-text-wrapper{
      text-align: center;
    }
}   
@media (max-width: 400px) {
  .content-heading .btn-wrapper .btn  {
    min-width:200px;
    margin-top:5px;
  }
  .content-heading .btn-wrapper.bordered  {
    border: none;
  }
}
</style>
