<template>
  <div>
    <div class="input-group-prepend search-icon-container" :class="{'input-loading' : getIsLoading}">
      <span class="input-group-text search-icon" @click="submitSearch()">
        <img :src="searchIcon" />
      </span>
      <input
        type="text"
        class="form-control search-input"
        placeholder="Filter by account name or ID"
        aria-label="search"
        autocomplete="off"
        @keyup="e=>submitSearch(e)"
        v-model="searchText"
      />
      <button class="close close-btn" v-if="searchText != ''" @click="clearSearch()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState  } from "vuex";
import SearchIcon from "../assets/images/search-icon.svg";
import { ClientTable, Event } from "vue-tables-2";
export default {
  name: "SearchFilter",
  components: {},
  props: {
    route: String,
  },
  data: function () {
    return {
      searchText: "",
      filteredOptions: [],
      searchIcon: SearchIcon,
    };
  },
  watch: {
    searchTerm() {
      this.searchText = this.searchTerm
      if (this.searchTerm) {
        this.submitSearch()
      }
    }
  },
  computed: {
    ...mapGetters(["getIsLoading", "getAllCorporateAccountsLoading"]),
    ...mapState(['tableReset']),
    searchTerm() {
      if (this.$route.query.q)
        this.searchText = this.$route.query.q
      return this.$route.query.q;
    },
    
  },
  mounted() {},
  methods: {
    ...mapMutations(["setTableReset"]),
    submitSearch(e) {
      Event.$emit("vue-tables.filter::search", this.searchText);
      if (this.tableReset == 0) {
        this.setTableReset(1)
      }
    },
    clearSearch(e) {
      this.searchText = "";
      Event.$emit("vue-tables.filter::search", this.searchText);
    },
  },
};
</script>
<style scoped>
.input-loading {
  cursor: not-allowed;
}
.input-loading input{
  cursor: not-allowed;
  pointer-events: none;
}
.input-loading .search-icon {
  cursor: not-allowed;
  pointer-events: none;
}
.search-icon {
  color: #ffffff;
  background-color: #000000;
  border-color: #ffffff;
  border-right: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search-input {
  color: #ffffff;
  background-color: #000000;
  border-color: #ffffff;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.search-input:focus {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #000000;
  box-shadow: none;
}
.search-input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}
.search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}
.search-input::placeholder {
  color: #ffffff;
}
.close-btn {
  color: white;
  position: absolute;
  right: 30px;
  top: -10px;
  height: 100%;
}
.close-btn:hover{
  color: white;
  position: absolute;
  right: 30px;
  top: -10px;
  height: 100%;
}
.close-btn svg {
  height: 16px;
}
.input-group-text img {
  width: 24px;
}
.search-icon-container {
  margin-bottom: 20px;
}
@media (max-width: 1200px) {

}
</style>