<template>
  <div>
    <div class="row">
      <div class="col-12">        
        <div v-if="!getAllFedrampAccountsLoading" class="all-accounts-table-container">
          <v-client-table class="table table-hover" v-model="getAllFedrampAccounts" :columns="allAccountColumns" :options="options" ref="accountTable">                        
            <span slot="accountCustomName" slot-scope="props">
              <button title="Open account details" class="btn-link" @click="toggleAccountDetails(props.row)">
                <div class="account-text">
                  <div class="child-toggler-icon">
                    <i class="fas fa-angle-down" v-if="detailsDisplayed.includes(props.row.accountId)" ></i> 
                    <i class="fas fa-angle-right" v-else></i> 
                  </div>
                  {{props.row.accountCustomName.toLowerCase() || "-"}}
                </div>
              </button>
            </span>
            <span slot="action" slot-scope="props">
              <a v-if="hasAccess(props.row)" class="access-link" @click="accessActionClick(props.row)"  v-on:click.middle="accessActionClick(props.row)"
                :href="parseLoginUrl(props.row)">
                  Launch
              </a>
              <p v-else>
                -
              </p>
              <!-- <a v-else title="Request access" href="https://autodesk.service-now.com/">
                Request access
              </a> -->
              <!-- <button v-else title="Request access" class="btn-link" @click="showAccessRequestModal(props.row)">
                Request access
              </button> -->
              <em v-if="getHasAdminRole && !hasValidAppId(props.row)" class="fa fa-exclamation app-id-notice" 
                :title="'Invalid App ID ('+props.row.azureAppId+')'"></em>
            </span>
            <template v-slot:child_row="props">
              <account-details :data="props.row"></account-details>
            </template>
          </v-client-table>
        </div>
        <div class="loading-container" v-else>
          <loading-spinner  />
        </div>
      </div>
    </div>
    <access-request-modal
      v-if="displayAccessRequestModal"
      :account="accessRequestAccount"
      @close="closeAccessRequestModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import LoadingSpinner from "@/components/LoadingSpinner";
import AccountDetails from "@/components/AccountDetails";
import * as C from "../constants.js";
import AccessRequestModal from '@/components/modal/AccessRequestModal.vue'

export default {
  name: "allFedrampAccountsTable",
  components: {
    LoadingSpinner,
    AccountDetails,
    AccessRequestModal
  },
  watch: {
    searchTerm() {
      console.log(this.searchTerm)
    }
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getAccounts",
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole",
      "getAllFedrampAccounts",
      "getAllFedrampAccountsLoading"]),
      searchTerm() {
        return this.$route.query.q;
      },
  },
  data: function () {
    return {
      C,
      allAccounts: [],
      allAccountColumns: ["accountCustomName",  "accountId", "action"],
      options: {
        customFilters: [
          {
            name: "search",
            callback: function (row, query) {
              let q = query.toLowerCase();
              let name = row.accountCustomName.toLowerCase();
              let id = row.accountId.toLowerCase();
              return name.includes(q) || id.includes(q);
            },
          },
        ],
        skin: "table",
        headings: {
          favorite: "*",
          accountCustomName: "Name",
          accountId: "Account ID",
          roles: "Active roles",
          action: "Actions",
        },
        uniqueKey: "accountId",
        sortable: [ "accountCustomName", "accountId"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No FedRAMP accounts to display",
        },
        headingsTooltips: {
          favorite : "Click to sort. Shift+Click to multi-sort.",
          accountId : "Click to sort. Shift+Click to multi-sort.",
          accountCustomName : "Click to sort. Shift+Click to multi-sort."
        },
        orderBy : {
          column: "accountCustomName"
        },
        perPageValues: [10,25,50],
        showChildRowToggler: false
      },
      isLoading: true,
      detailsDisplayed:[],
      displayAccessRequestModal: false,
      accessRequestAccount: null
    };
  },
  created() {
  },
  methods: {    
    ...mapActions(["addAlert"]),
    parseDate(date) {
      return util.parseDate(date)
    },
    parseLoginUrl(item) {
      return util.parseLoginUrl(item,  util.generateCommercialUserId(this.getAppUser))
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    async toggleAccountDetails(row) {
      
      if (this.detailsDisplayed.includes(row.accountId)) {
        this.detailsDisplayed.splice(this.detailsDisplayed.indexOf(row.accountId), 1)
        this.trackEvent(C.ACTION_TYPES.HIDE_VIEW_DETAIL, this.$router.currentRoute.path+"?view=all-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      } else {
        this.detailsDisplayed.push(row.accountId)
        this.trackEvent(C.ACTION_TYPES.SHOW_VIEW_DETAIL, this.$router.currentRoute.path+"?view=all-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      }

      this.$refs.accountTable.toggleChildRow(row.accountId);
    },
    showAccessRequestModal(item) {   
      this.trackEvent(C.ACTION_TYPES.ACCESS_REQUEST_LINK, 
        this.$router.currentRoute.path+"?view=all-accounts",  
        `account_id=${item.accountId} name=${item.accountCustomName}`);
      
      this.displayAccessRequestModal = true
      this.accessRequestAccount = item
    },
    closeAccessRequestModal() {
      this.displayAccessRequestModal = false
      this.accessRequestAccount = null
    },
    hasAccess(account) {
      let myAccount = this.getAccounts.find( ele => {
        return ele.accountId == account.accountId
      })
      if (myAccount) {
        return true
      }
      return false
    }
  },
};
</script>
<style scoped>

.account-text{
  color:#5a5a5a;
  display:inline-flex;
}
.child-toggler-icon{
  font-size: 14px;
  margin-right: 8px;
  color:#5f60ff;
}
.cache-info-text {
  font-size: 14px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.accounts-table-container {
  margin-top: 5px;
  font-size: 14px;
}
.access-link {
  font-weight: bolder;
  padding-left: 6px;
}
.app-id-notice {
  color: red;
  font-size: 14px;
}
.sync-btn {
  border: none;
  background: transparent;
  color: #5f60ff;
  outline: none;
  margin-left: 5px;
  font-size: 14px;
}
.sync-btn:hover {
  color: #4f50f1;
}
.accounts-toggle-button{
  float: left;
  margin-right: 15px;
}
.accounts-toggle-text{
  font-size: 14px;
  padding-top:2px;  
}
</style>
