<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">FedRAMP Account Request Access </strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <div class="form-group">
                <label>Environment: </label>
                <form class="d-flex justify-content-around">
                  <label class="radio-inline">
                    <input type="radio" name="requestedEnv" value="stage" v-model="requestedEnv"> Stage
                  </label>
                  <label class="radio-inline">
                    <input type="radio" name="requestedEnv" value="prod" v-model="requestedEnv"> Prod
                  </label>
                </form>
              </div>
            </div>
            <div class="col-12">
              <p style="text-align: start; margin-bottom: 12px">
                <i class="fas fa-info-circle"/> Autodesk Cloud Products internal engineering accounts (adskeng.net) can be requested <strong><a target="_blank" href="https://one.autodesk.com/apps/account-management/"> here</a></strong>.
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="offset-md-2 col-md-8">
                <button class="modal-default-button btn flat-btn blue-btn" @click="requestAccess()">
                  <strong>Request Access <i class="fas fa-external-link-square-alt"/></strong><br/>(use your adskeng.net user)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as C from "../../constants.js";
import util from "@/util";

export default {
  name: "FedrampRequestAccessModal",
  props: {
    commercialId: String
  },
  components: {
  },
  computed: {
    ...mapGetters(["getAppUser"])
  },
  data: function() {
    return {
      C,
      requestedEnv:"stage"
    };
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    getCommercialId() {
      return util.generateCommercialUserId(this.getAppUser)
    },
    requestAccess(){
      console.log("Logging current env", this.requestedEnv)
      let accessLink = ""
      switch (this.requestedEnv){
        case "stage":
          accessLink = 'https://myaccess.microsoft.com/@adskengpp.onmicrosoft.com?login_hint='
          break;
        case "prod":
          accessLink = 'https://myaccess.microsoft.com/@adskeng.onmicrosoft.com?login_hint='+ this.getCommercialId()
          break;
      }
      window.open(accessLink, '_blank');
      this.close()
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  text-align: center;
  line-height: 18px;
}
.form-group {
  text-align: left;
}
.status-icon svg {
  margin: auto;
  display: block;
}
.btn {
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.close-btn {
  font-size: 18px;
  color: white;
}
.close-btn:hover {
  color: white;
}
.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background:  rgba(0, 0, 0, 0.9);
}
.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}
.modal-body {
  padding: 0 12px;
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}
.modal-body p {
  margin-bottom: 5px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.modal-footer {
  margin-top: 20px;
  padding: 0;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0 auto;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}
.modal-default-button {
  float: right;
  padding: 8px 24px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>