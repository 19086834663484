<template>
  <div class="container-fluid no-gutters p-0">
    <table v-bind="props.tableAttrs">
      <caption v-if="props.caption">{{props.caption}}</caption>
      <vt-table-head />
      <vnodes :vnodes="props.slots.beforeBody" />
      <vt-table-body />
      <vnodes :vnodes="props.slots.afterBody" />
      <tfoot>
      </tfoot>
    </table>
    <div class="row no-gutters">
      <div class="col-6">
        <vt-per-page-selector />
      </div>
      <div class="col-6">
        <vt-pagination :props="props" />
      </div>
    </div>
  </div>
</template>


<script>
import VtTableHead from "vue-tables-2/compiled/components/VtTableHead";
import VtTableBody from "vue-tables-2/compiled/components/VtTableBody";
import VtPagination from "vue-tables-2/compiled/components/VtPagination";
import VtPerPageSelector from "vue-tables-2/compiled/components/VtPerPageSelector";

export default {
  name: "VtTable",
  props: ["props"],
  components: {
    VtTableHead,
    VtTableBody,
    VtPagination,
    VtPerPageSelector,
    vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
};
</script>