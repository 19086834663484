import Vue from "vue";
import util from '../util'
export default {
  data() {
    return {
    };
  },
  methods: {

    trackEvent(type, view, reference) {  
      
      util.saveUserAction(type, view, reference)
      let eventStr = "Page Event: Cloud Login Portal - " + type
      let addtlParams = {}
      addtlParams.subEvent = type+" "+view+" "+reference
      
      this.$hhMixpanel.trackEvent(eventStr, addtlParams)    
    }
  }
}
