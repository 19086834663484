<template>
<div class="container " style="margin-top:5px;">
  <div class="row">
    <div class="col-12" >
      <div class="notification-collapse-header" @click="showNotifs" v-if="notifications.length > 0">
          <i class="fas fa-exclamation-triangle"></i>
          <span>You have notifications ({{notifications.length}}). Click to view.</span>
      </div>
      <div class="notification-content" v-if="displayNotifications">
        <div class="notification-record" v-for="(notif, index) in notifications" :key="index">
          <div v-if="notif.entityStatus == 'ACTIVE'" :class="'alert alert-'+parseSeverity(notif.severity)" role="alert">
            <span>
              <b>{{notif.subject}}</b> : {{notif.message}}
            </span>
            <!-- <button ref="closeBtn" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="notif.entityStatus='DISMISSED'">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import {
  mapGetters
} from 'vuex'
import * as CONSTANTS from '../constants.js'

export default {
  name: 'NotificationsContainer',
  components: {},
  props: {

  },
  data: function () {
    return {
      CONSTANTS,
      displayNotifications : false
    }
  },
  computed: {
    ...mapGetters(['getNotifications', 'getUser']),
    notifications: {
      get: function () {
        return this.getNotifications.filter( notif => {
          return notif.entityStatus == 'ACTIVE'
        })
      }
    },
  },
  mounted () {
  },
  methods: {
    showNotifs() {
      this.displayNotifications = !this.displayNotifications 
    },
    parseSeverity(str) {
      switch (str) {
        case "LOW" :
          return "info"
        case "MED" :
          return "warning"
        case "HIGH" :
          return "danger"
        default:
          return "info"
      }
    }
  }
}
</script>

<style scoped>
.notification-collapse-header {
  cursor: pointer;  
  color: #ffa500;
  margin-bottom: 10px;
}
.notification-collapse-header i {
  margin-right: 10px;
}
.alert {
  margin-bottom: 5px;
}
.helpdesk-chat-link {
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: #0696d7;
  text-decoration: underline;
}

</style>
