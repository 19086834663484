<template>
<div class="container alert-container">
  <div class="row">
    <div class="col-12">
      <div v-for="(alert, index) in getAlerts" :key="index">
        <div v-if="alert.active" :class="'alert alert-'+alert.contextClass" role="alert">
          <span v-if="Array.isArray(getErrorMessage(alert))" >
            {{getErrorMessage(alert)[0]}}
            <button class="reload-link" v-on:click="reloadAccounts(alert)">Click here to reload</button>
            {{getErrorMessage(alert)[1]}}
          </span>
          <span v-else>
            <span v-html="getErrorMessage(alert)"></span>
          </span>
          
          <button ref="closeBtn" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert.active=false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import * as CONSTANTS from '../constants.js'

export default {
  name: 'AlertContainer',
  components: {},
  props: {

  },
  data: function () {
    return {
      CONSTANTS,
      msg: ''
    }
  },
  computed: {
    ...mapGetters(['getAlerts', 'getAppUser'])   
  },
  mounted () {

  },
  methods: {
    ...mapActions(["loadAccounts"]),
    getErrorMessage(alert) {
      let ret 
      ret = alert.msg
      if (ret.indexOf('<RELOAD_LINK/>') > -1) {
        ret = ret.split('<RELOAD_LINK/>')
      }
      return ret;
    },
    launchChat() {
      //base64 encode user email and pass to chat link as p1
      let chatLink = CONSTANTS.INCONTACT_CHAT_LINK+"&p1="+btoa(this.getAppUser.email)
      var popupProps = '';
      var w=500;
      var h=600;
      var left = (screen.width/2)-(w/2);
      var top = (screen.height/2)-(h/2);
      popupProps = 'scrollbars=yes, resizable=yes, width='+w+', height='+h+', top='+top+', left='+left;                                       
      window.open(chatLink, 'popup', popupProps);
    },
    async reloadAccounts(alert) {
      alert.active = false
      await this.loadAccounts()
    }
  }
}
</script>

<style scoped>

.alert-container{
  margin-top: 5px;
}

.alert {
  margin-bottom: 5px;
}
.reload-link {
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: #0696d7;
  text-decoration: underline;
}

</style>
