<template>
  <div class="container">
    <div class="row content-heading" >
      <div class="col-sm-4">
        <div class="btn-wrapper">
          <button class="btn flat-btn blue-btn" :class="{'selected' : displayMyAccounts}" @click="showMyAccounts">
            My accounts
          </button>
        </div>
<!--        <div class="btn-wrapper">-->
<!--          <button class="btn flat-btn blue-btn" :class="{'selected' : displayAllAccounts}" @click="showAllAccounts">-->
<!--            All accounts-->
<!--          </button>-->
<!--        </div>-->
      </div>
<!--      <div class="col-sm-12">-->
<!--        <p class="help-text-wrapper">-->
<!--        <em class="account-help-text">-->
<!--          To access AWS Console for a specific AWS account, click on “Launch” in the list below.-->
<!--          <br>-->
<!--          To request access to FedRAMP AWS Accounts/Business Services follow these links:-->
<!--          <ul class="mb-0">-->
<!--            <li>Request access to <strong><a target="_blank" :href="'https://myaccess.microsoft.com/@adskengpp.onmicrosoft.com?login_hint='+getCommercialId()">Stage</a></strong> (use your adskeng.net user)</li>-->
<!--            <li>Request access to <strong><a target="_blank" :href="'https://myaccess.microsoft.com/@adskeng.onmicrosoft.com?login_hint='+getCommercialId()">Prod</a></strong> (use your adskeng.net user)</li>-->
<!--          </ul>-->
<!--          Autodesk Cloud Products internal engineering accounts (adskeng.net) can be requested <strong><a target="_blank" href="https://employeehub.autodesk.com/apps/account-management/"> here</a></strong>.-->
<!--          <br>-->
<!--          For help, please check the -->
<!--          <strong><a target="_blank" href="https://employeehub.autodesk.com/IT/articles/fff1c7f51bdef4501e13a931604bcb32"> FAQ </a></strong>-->
<!--          or contact support on Slack:-->
<!--          <strong><a target="_blank" href="https://autodesk.slack.com/archives/CDYEFBL9L"> #adsk-cloud-support</a></strong>.-->
<!--        </em></p>-->
<!--      </div>-->
      <div class="offset-6 col-2 d-flex flex-column justify-content-end align-items-end">
        <button class="btn flat-btn blue-btn" style="border-radius: 8px"  @click="showFedrampRequestAccessModal()">Request Access</button>
      </div>
    </div>
    <my-fedramp-accounts-table-v2 v-if="displayMyAccounts"></my-fedramp-accounts-table-v2>
    <all-fedramp-accounts-table v-if="displayAllAccounts"></all-fedramp-accounts-table>
    <transition name="request-access-modal">
      <fedramp-request-access-modal
          v-if="displayFedrampRequestAccessModal"
          @close="closeFedrampRequestAccessModal"
      />
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import util from '@/util'
import LoadingSpinner from "@/components/LoadingSpinner";
import MyFedrampAccountsTable from "@/components/MyFedrampAccountsTable";
import MyFedrampAccountsTableV2 from "@/components/MyFedrampAccountsTableV2.vue";
import AllFedrampAccountsTable from "@/components/AllFedrampAccountsTable";
import * as C from "../constants.js";
import FedrampRequestAccessModal from "@/components/modal/FedrampRequestAccessModal.vue";

export default {
  name: "FedrampDashboard",
  components: {
    MyFedrampAccountsTableV2,
    FedrampRequestAccessModal,
    LoadingSpinner,
    MyFedrampAccountsTable,
    AllFedrampAccountsTable
  },
  computed: {
    viewFilter() {
      return this.$route.query.view;
    },
    ...mapGetters(["getAppUser", "getAlerts"]),
  },
  data: function () {
    return {
      C,
      displayMyAccounts: true,
      displayAllAccounts: false,
      displayFedrampRequestAccessModal: false
    };
  },
  created() {
    if (this.viewFilter == "my-accounts") {
      this.showMyAccounts()
    } else if (this.viewFilter == "all-accounts") {
      this.showAllAccounts()
    }
    this.addAlert(
        util.createAlert(0,
            C.FEDRAMP_BANNER,
            C.ALERT_CONTEXT.WARNING
        )
    );
    this.addAlert(
        util.createAlert(0,
            C.JIT_BANNER,
            C.ALERT_CONTEXT.INFO
        )
    );
  },
  beforeDestroy() {
    this.getAlerts.filter(alert => alert.msg === C.JIT_BANNER || alert.msg === C.FEDRAMP_BANNER).forEach(alert => {
      alert.active = false
    })
  },
  methods: {
    ...mapActions(['addAlert']),
    showMyAccounts() {
      this.displayMyAccounts = true
      this.displayAllAccounts = false
      if (this.viewFilter != "my-accounts") {
        this.$router.push({ path: '?view=my-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-my-accounts`); 
      }
    },
    showAllAccounts() {
      this.displayAllAccounts = true
      this.displayMyAccounts = false
      if (this.viewFilter != "all-accounts") {
        this.$router.push({ path: '?view=all-accounts'})
        this.trackEvent(C.ACTION_TYPES.VIEW_FILTER, this.$router.currentRoute.path,  `show-all-accounts`); 
      }
    },
    getCommercialId() {
      return util.generateCommercialUserId(this.getAppUser)
    },
    showFedrampRequestAccessModal() {
      this.trackEvent(C.ACTION_TYPES.FEDRAMP_ACCESS_REQUEST_LINK, this.$router.currentRoute.path);
      this.displayFedrampRequestAccessModal = true
    },
    closeFedrampRequestAccessModal() {
      this.displayFedrampRequestAccessModal = false
    },
  }
};
</script>
<style scoped>
.content-heading {
  margin-top: 10px;
  min-height: 30px;
}
.content-heading .btn{
  margin-right: 10px;
  border-radius: 15px;
}
.content-heading .btn-wrapper {
  display: inline-block;
  margin-right: 10px;
}
.content-heading .btn-wrapper.bordered {
  display: inline-block;
  margin-right: 10px;
  border-right: 2px solid #929292;
}
.request-access-modal-enter-active {
  transition: all .3s ease-in;
}
.request-access-modal-leave-active {
  transition: all .3s ease-out;
}
.request-access-modal-enter, .request-access-modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
.account-help-text {
  float: left;
  font-size: 12px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
    .account-help-text {
        float: none;        
    }
    .help-text-wrapper{
      text-align: center;
    }
}   
@media (max-width: 400px) {
  .content-heading .btn-wrapper .btn  {
    min-width:200px;
    margin-top:5px;
  }
  .content-heading .btn-wrapper.bordered  {
    border: none;
  }
}
</style>
