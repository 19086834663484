// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/images/banner-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".app-page-title[data-v-22e7a9fa]{float:left;margin-right:10px;font-size:xx-large;font-weight:700}.outage-alert-container[data-v-22e7a9fa]{background-color:#ffc21a;color:#000;line-height:1.5;padding:12px}@media (min-width:1800px){.title-wrapper[data-v-22e7a9fa]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;color:#fff;padding-top:20px}}@media (max-width:1800px){.title-wrapper[data-v-22e7a9fa]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;color:#fff;padding-top:20px}}.title-wrapper h1[data-v-22e7a9fa]{margin-bottom:5px}.title-wrapper p[data-v-22e7a9fa]{font-size:14px}.content-wrapper[data-v-22e7a9fa]{background-color:#fff;margin-bottom:20px}.footer[data-v-22e7a9fa],.loading-container[data-v-22e7a9fa]{margin-top:50px}.cache-info-text[data-v-22e7a9fa]{font-size:10px}.notification-dot[data-v-22e7a9fa]{position:absolute;top:5px;width:5px;height:5px;background-color:red;border-radius:50%}.nav-wrapper[data-v-22e7a9fa]{background-color:#f4f6f8}.navbar-toggler[data-v-22e7a9fa]{margin:15px}.navbar[data-v-22e7a9fa]{padding:0}.navbar a[data-v-22e7a9fa]{font-weight:700;color:#575757}.nav-tabs[data-v-22e7a9fa]{border-bottom:none}.nav-item .nav-text[data-v-22e7a9fa]{font-size:14px;margin-bottom:5px}.nav-item .nav-icon[data-v-22e7a9fa]{font-size:16px}.nav-tabs .nav-link[data-v-22e7a9fa]{border-top-left-radius:0;border-top-right-radius:0}.router-link-active[data-v-22e7a9fa]{background-color:#fff;color:#5f60ff!important;border-bottom-color:transparent!important}@media (min-width:1200px) and (max-width:1300px){.title-container[data-v-22e7a9fa]{padding-left:58px!important;margin-left:0}}.whats-new-modal-enter-active[data-v-22e7a9fa]{transition:all .3s ease-in}.whats-new-modal-leave-active[data-v-22e7a9fa]{transition:all .3s ease-out}.whats-new-modal-enter[data-v-22e7a9fa],.whats-new-modal-leave-to[data-v-22e7a9fa]{transform:scale(1.1);opacity:0}", ""]);
// Exports
module.exports = exports;
