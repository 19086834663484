import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/*** Font Awesome ***/
import "@fortawesome/fontawesome-free/css/all.min.css"

/*** CSS ***/
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import './assets/stylesheets/style.css'
import './assets/stylesheets/helphub-overrides.css'

import { ToggleButton } from 'vue-js-toggle-button'
import TrackingMixin from '@/mixins/TrackingMixin'
import {ClientTable } from 'vue-tables-2';
import DataTable from './components/table/DataTable'
import Table from './components/table/Table'
import PerPageSelector from './components/table/PerPageSelector'
import Pagination from './components/table/Pagination'
import SortControl from './components/table/SortControl'

/*** Setup Help Hub core component ***/
var headElement = document.getElementsByTagName("head")[0];

var vueScriptElement = document.createElement("script");
vueScriptElement.src = "https://del6fkf4zntcx.cloudfront.net/js/vue.min.js";
headElement.appendChild(vueScriptElement);

var componentScriptElement = document.createElement("script");
componentScriptElement.src = process.env.VUE_APP_HH_COMPONENTS_URL + "/helphubcore.umd.min.js?v=" + Date.now();
headElement.appendChild(componentScriptElement);
Vue.config.ignoredElements = ["help-hub-header"];

Vue.component('ToggleButton', ToggleButton)

let tableOpts = {
  sortIcon: {
    base: "fas",
    up: "fa-sort-up",
    down: "fa-sort-down",
    is: ""
  }
}

Vue.use(ClientTable, tableOpts, false, 'bootstrap4', {
  "dataTable": DataTable,
  "table": Table,
  "perPageSelector" : PerPageSelector,
  "pagination" : Pagination,
  "sortControl" : SortControl
});

Vue.config.productionTip = false

Vue.mixin(TrackingMixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
