import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/api";
import util from '@/util'
import * as C from '../constants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: true,
    appUser: {},
    healthReport: {},
    alerts: [],
    accounts: [],
    commercialAccounts: [],
    fedrampAccounts: [],
    favorites: [],
    whatsNew: [],
    notifications: [],
    allCorporateAccounts: [],
    allCorporateAccountsLoading: true,

    allCommercialAccounts: [],
    allCommercialAccountsLoading: true,

    allFedrampAccounts: [],
    allFedrampAccountsLoading: true,

    tokenData: {},
    cacheDataDate: undefined,
    tableReset: 0,
    hasAdminRole : false
  },
  mutations: {
    setIsLoading (state, isLoading) {
      state.isLoading = isLoading
    },
    setAppUser(state, appUser) {
      state.appUser = appUser
    },
    setHealthReport(state, healthReport) {
      state.healthReport = healthReport
    },
    setAlerts (state, alerts) {
      state.alerts = alerts
    },
    setAccounts (state, accounts) {
      state.accounts = accounts
    },
    setCommercialAccounts (state, commercialAccounts) {
      state.commercialAccounts = commercialAccounts
    },
    setFedrampAccounts (state, fedrampAccounts) {
      state.fedrampAccounts = fedrampAccounts
    },
    setFavorites (state, favorites) {
      state.favorites = favorites
    },
    setNotifications (state, notifications) {
      state.notifications = notifications
    },
    setWhatsNew(state, whatsNew){
      state.whatsNew = whatsNew
    },
    setAllCorporateAccounts (state, allCorporateAccounts) {
      state.allCorporateAccounts = allCorporateAccounts
    },
    setAllCorporateAccountsLoading (state, allCorporateAccountsLoading) {
      state.allCorporateAccountsLoading = allCorporateAccountsLoading
    },

    setAllCommercialAccounts (state, allCommercialAccounts) {
      state.allCommercialAccounts = allCommercialAccounts
    },
    setAllCommercialAccountsLoading (state, allCommercialAccountsLoading) {
      state.allCommercialAccountsLoading = allCommercialAccountsLoading
    },

    setAllFedrampAccounts (state, allFedrampAccounts) {
      state.allFedrampAccounts = allFedrampAccounts
    },
    setAllFedrampAccountsLoading (state, allFedrampAccountsLoading) {
      state.allFedrampAccountsLoading = allFedrampAccountsLoading
    },


    setTokenData (state, tokenData) {
      state.tokenData = tokenData
    },
    setCacheDataDate (state, cacheDataDate) {
      state.cacheDataDate = cacheDataDate
    },
    setTableReset  (state, tableReset) {
      state.tableReset = tableReset
    },
    setHasAdminRole (state, hasAdminRole) {
      state.hasAdminRole = hasAdminRole
    }
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getAppUser(state) {
      return state.appUser
    },
    getHealthReport(state) {
      return state.healthReport
    },
    getAlerts(state) {
      return state.alerts
    },
    getAccounts(state) {
      return state.accounts
    },
    getCommercialAccounts(state) {
      return state.commercialAccounts
    },
    getFedrampAccounts(state) {
      return state.fedrampAccounts
    },
    getAllCorporateAccounts(state) {
      return state.allCorporateAccounts
    },
    getAllCorporateAccountsLoading(state) {
      return state.allCorporateAccountsLoading
    },
    
    getAllCommercialAccounts(state) {
      return state.allCommercialAccounts
    },
    getAllCommercialAccountsLoading(state) {
      return state.allCommercialAccountsLoading
    },

    getAllFedrampAccounts(state) {
      return state.allFedrampAccounts
    },
    getAllFedrampAccountsLoading(state) {
      return state.allFedrampAccountsLoading
    },

    getFavorites(state) {
      return state.favorites
    },
    getsWhatsNew(state){
      return state.whatsNew
    },
    getNotifications(state) {
      return state.notifications
    },
    getTokenData(state) {
      return state.tokenData
    },
    getCacheDataDate(state) {
      return state.cacheDataDate
    },
    getTableReset(state) {
      return state.tableReset
    },
    getHasAdminRole(state) {
      return state.hasAdminRole
    }
  },
  actions: {
    async initialize ({ commit, dispatch }) {
      
      try {
        commit('setIsLoading', true)

        let accountsRequest = api.getMyAccounts();
        let favoritesRequest = api.getMyFavorites();
        let accountCacheRequest = api.getMyAccountsCache();
        let whatsNewRequest = api.getWhatsNew();
        let healthCheckRequest = api.getServiceHealth();
        
        // Get All AWS Accounts and filter them
        // dispatch('loadAllAccounts')

        let cacheDataError = false
        let favoritesError = false
        let whatsNewError = false
        let healthCheckError = false

        // Wait for cache request and favorites to resolve
        let resps = await Promise.all([
          accountCacheRequest.catch(err => { 
            console.log("Error getting cached accounts.", err)
            cacheDataError = true
          }),
          favoritesRequest.catch(err =>{
            console.log("Error getting favorites. ", err)
            favoritesError = true
            dispatch('addAlert', 
              util.createAlert(null, 
                C.ERROR_MSGS.FAVORITES_ERROR, 
                C.ALERT_CONTEXT.WARNING))
          }),
          whatsNewRequest.catch((err)=>{
            console.log("Error getting Whats New", err)
            whatsNewError = true
            dispatch('addAlert',
                util.createAlert(null,
                    C.ERROR_MSGS.WHATSNEW_ERROR,
                    C.ALERT_CONTEXT.WARNING))
          }),
          healthCheckRequest.catch((err)=>{
            console.log("Error getting health check", err)
            healthCheckError = true
          })]
        )
        
        let accounts = []
        let commercialAccounts = []
        let fedrampAccounts = []

        try {
          // If there is an error getting cache data use live data
          if (cacheDataError) { 
            let accResp = await accountsRequest
            let myAccountsData = accResp.data.data     
            accounts = util.filterAccounts(myAccountsData, "DES")
            commercialAccounts = util.filterAccounts(myAccountsData, "MCP")
            fedrampAccounts = util.filterAccounts(myAccountsData, "MCP-FEDRAMP")
          // otherwise set accounts with cachedata
          } else {
            let myAccountsData = resps[0].data.data.accounts
            accounts = util.filterAccounts(myAccountsData, "DES")
            commercialAccounts = util.filterAccounts(myAccountsData, "MCP")
            fedrampAccounts = util.filterAccounts(myAccountsData, "MCP-FEDRAMP")

            let accountCompare = JSON.stringify(myAccountsData)

            if (myAccountsData.length > 0) {
              commit('setCacheDataDate', resps[0].data.data.created)
              // Check cacheData against live accounts when the request has resolved. 
              accountsRequest.then( resp => {
                let updatedAccounts = resp.data.data
                let a = accountCompare
                let b = JSON.stringify(updatedAccounts)
                // if the live accounts do not match the cached, notify the user
                if (a != b) {
                  dispatch('addAlert', util.createAlert(null, C.ACCOUNTS_UPDATED_MSG, C.ALERT_CONTEXT.INFO))
                }
              }).catch( err =>{
                console.log("Error loading /accounts", err)
              })
            } else {
              console.log("No user accounts in cache. Awaiting accounts.")
              let accResp = await accountsRequest
              myAccountsData = accResp.data.data     
              accounts = util.filterAccounts(myAccountsData, "DES")
              commercialAccounts = util.filterAccounts(myAccountsData, "MCP")
              fedrampAccounts = util.filterAccounts(myAccountsData, "MCP-FEDRAMP")
            }
          }
        } catch (err) {
          console.log("ERR", err)
          dispatch('addAlert', util.createAlert(null, C.ERROR_MSGS.ACCOUNTS_CACHE_ERROR, C.ALERT_CONTEXT.DANGER))
        }

        accounts.sort(util.accountNameSort)
        commercialAccounts.sort(util.accountNameSort)
        fedrampAccounts.sort(util.accountNameSort)

        let whatsNew = []
        if(!whatsNewError){
          whatsNew = resps[2].data.data
          whatsNew.sort((a,b)=> parseInt(b.releaseNoteId) - parseInt(a.releaseNoteId))
        }

        let favorites = []
        if (!favoritesError) {

          favorites = resps[1].data.data
          util.favoritesSortAccounts(accounts,favorites)
          util.favoritesSortAccounts(commercialAccounts,favorites)
          util.favoritesSortAccounts(fedrampAccounts,favorites)
          
        }
        
        let hasAdminRole = accounts.some( ele =>{
          return ele.azureAppData.some( data => {
            return data.displayName == C.GLOBAL_ADMINS_ROLE
          })
        })
        commit('setHasAdminRole', hasAdminRole)

        if (!hasAdminRole) {          
          accounts = accounts.filter( ele => {
            return util.hasValidAppId(ele)
          })
        }

        let healthReport = {}
        if(!healthCheckError){
           healthReport = resps[3].data.data
        }

        commit('setAccounts', accounts)
        commit('setCommercialAccounts', commercialAccounts)
        commit('setFedrampAccounts', fedrampAccounts)
        commit('setFavorites', favorites)
        commit('setWhatsNew', whatsNew)
        commit('setHealthReport', healthReport)

      } catch (err) {
        console.error("Error initializing app", err)
        dispatch('addAlert', util.createAlert(null, C.ERROR_MSGS.GENERIC_ERROR, C.ALERT_CONTEXT.DANGER))
      } finally {
        commit('setIsLoading', false)
      }

      // try {
      //   let body = {
      //     accountIds : this.state.accounts.map(ele =>{
      //       return ele.accountId
      //     })
      //   }
      //   let resp = await  api.getNotifications(body);
      //   let notifs = resp.data.data
      //   commit('setNotifications',notifs)
      //   notifs.sort((a,b) =>{
      //     if (a.severity == "HIGH") {
      //       return -1
      //     }
      //     if (a.severity == "MED" && b.severity == "LOW") {
      //       return -1
      //     }
      //     return 0
      //   })
      // } catch (err) {
      //   console.error("Error getting notifications", err)
      // }
    },
    async loadAccounts ({state, commit, dispatch }) {
      
      console.log("re-loading accounts")
      try {
        commit('setIsLoading', true)        

        let accountsRequest = await api.getMyAccounts();
        let myAccountsData = accountsRequest.data.data
        
        let accounts = util.filterAccounts(myAccountsData, "DES")
        let commercialAccounts = util.filterAccounts(myAccountsData, "MCP")
        let fedrampAccounts = util.filterAccounts(myAccountsData, "MCP-FEDRAMP")

        accounts.sort(util.accountNameSort)
        commercialAccounts.sort(util.accountNameSort)
        fedrampAccounts.sort(util.accountNameSort)

        let favorites =  this.state.favorites
        util.favoritesSortAccounts(accounts,favorites)
        util.favoritesSortAccounts(commercialAccounts,favorites)
        util.favoritesSortAccounts(fedrampAccounts,favorites)


        commit('setCacheDataDate', util.getToday())

        if (!state.hasAdminRole) {          
          accounts = accounts.filter( ele => {
            return util.hasValidAppId(ele)
          })
        }

        commit('setAccounts', accounts)
        commit('setCommercialAccounts', commercialAccounts)
        commit('setFedrampAccounts', fedrampAccounts)

      } catch (err) {
        console.error("Error loading accounts", err)
        dispatch('addAlert', util.createAlert(null, C.ERROR_MSGS.ACCOUNTS_ERROR, C.ALERT_CONTEXT.DANGER))
      } finally {
        commit('setIsLoading', false)
      }
    },
    async loadAllAccounts ({state, commit, dispatch }) {
      let allAccountsRequest = api.getAllAccounts()
      allAccountsRequest.then( resp =>{
        let data =  resp.data.data
        commit('setAllCorporateAccounts', data.filter( ele =>{
          return ele.profile == "DES"
        }))
        commit('setAllCorporateAccountsLoading', false)

        commit('setAllCommercialAccounts', data.filter( ele =>{
          return ele.profile == "MCP"
        }))
        commit('setAllCommercialAccountsLoading', false)

        commit('setAllFedrampAccounts', data.filter( ele =>{
          return ele.profile == "MCP-FEDRAMP"
        }))
        commit('setAllFedrampAccountsLoading', false)

      }).catch(err => {
        console.err("Error loading all accounts", err)
        commit('setAllCorporateAccounts', [])
        commit('setAllCorporateAccountsLoading', false)
        dispatch('addAlert', 
            util.createAlert(null, 
              C.ERROR_MSGS.ALL_CORP_ACCOUNTS_ERROR, 
              C.ALERT_CONTEXT.WARNING))
      });
      
    },
    addAlert ({ state, commit }, alert) {
      var alerts = state.alerts
      
      var existingAlert = alerts.find((e) => {
        return e.active && e.msg == alert.msg && e.contextClass == alert.contextClass
      })
      if (existingAlert) {  
        alert.active = false;
      }
      alerts.push(alert)
      commit('setAlerts', alerts)
    }
  },
  modules: {
  }
})
