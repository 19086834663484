<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">Raise PIM Request </strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="input-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label >Account name</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.accountCustomName" />
                  </div>
                  <div class="form-group">
                    <label >Account ID</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.accountId" />
                  </div>
                  <div class="form-group">
                    <label >Environment</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="account.environment" />
                  </div>
                  <div class="form-group">
                    <label >Requested role * </label>
                    <select class="form-control" v-model="requestedRole">
                      <option v-for="option in availableRolesToRequest" :key="option">{{option}}</option>
                    </select>
                    <em v-if="requestedRoleError" class="required-text">* This is a required field.</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="offset-md-1 col-md-10">
                <button class="modal-default-button btn flat-btn blue-btn" @click="raiseRequest()">
                  <strong>Raise Request <i class="fas fa-external-link-square-alt"/></strong><br/><i style="font-size: xx-small">( use your adskeng.net user - {{account.environment === 'PRD' ? "Autodesk Engineering" : "Autodesk Engineering Stage"}} Directory </i>)
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
import util from "@/util";
import * as C from "../../constants.js";

export default {
  name: "PimRequestModal",
  props: {
    account: Object
  },
  computed: {
    ...mapGetters([""])
  },
  data: function() {
    return {
      C,
      requestedRoleError: false,
      requestedRole: "",
      availableRolesToRequest: []
    };
  },
  mounted() {
    this.computeRolesAvailableToRequest()
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    computeRolesAvailableToRequest(){
      let eligibleRoles = util.parseRole(this.account).split(", ").filter((role) => role.includes("Eligible"))
      let availableRolesToRequest = eligibleRoles.map((role)=> role.replace("-Eligible", ""))
      this.availableRolesToRequest = availableRolesToRequest
      this.requestedRole = availableRolesToRequest[0]
    },
    async raiseRequest() {
      if (util.stringIsBlank(this.requestedRole)) {
        this.requestedRoleError = true
        return
      }

      let pimURL = ""
      let directoryDomain;
      switch (this.account.environment) {
        case 'TEST':
        case 'DEV':
          directoryDomain = '@autodesk.onmicrosoft.com';
          break;
        case 'STG':
          directoryDomain = '@adskengpp.onmicrosoft.com';
          break;
        case 'PRD':
          directoryDomain = '@adskeng.onmicrosoft.com';
          break;
      }
      try{
        // Finding the security group corresponding to the requested eligible role selected by user
        let requestedSG = this.account.azureAppData.filter((ele)=> ele.displayName.includes(this.requestedRole))

        // Parsing the name of the Account, user is eligible to PIM to. ex: Owner-Eligible -> Owner
        let requestedPIMAccDisplayName = requestedSG[0].displayName.replace("-Eligible", "")

        // Getting the obj id for the group using azure graph query, so that we can redirect user to pim link
        let azureSgResponse = await api.getAccountAzureInfo(this.account.accountId, {"profile": this.account.profile, "env": this.account.environment, "role": this.requestedRole})
        let azureSgObjectId = azureSgResponse.data.data[0].id

        pimURL = `https://portal.azure.com/${directoryDomain}#view/Microsoft_Azure_PIMCommon/ResourceMenuBlade/~/MyActions/resourceId/${azureSgObjectId}/resourceType/Security/provider/aadgroup/resourceDisplayName/${requestedPIMAccDisplayName}/resourceExternalId/${azureSgObjectId}`

      } catch (e){
        // In case of any error, we redirect user to Azure portal to raise Pim
        pimURL = `https://portal.azure.com/${directoryDomain}#view/Microsoft_Azure_PIMCommon/ActivationMenuBlade/~/aadmigratedroles`
        console.log("Error occurred in raising PIM Request", e)
      }
        window.open(pimURL, '_blank')
        this.close()
    }
  }
};
</script>

<style scoped>
.required-text {
  width: 100%;
  margin-top: .25rem;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
  font-size: 80%;
  color: #dc3545;
}
.form-control {
  border-radius: 4px;
}
.modal-wrapper {
  text-align: center;
  line-height: 18px;
}
.form-group {
  text-align: left;
}
.status-icon {
  font-size: 50px;
  margin-bottom:20px;
}
.status-title {
  color: #575757;
  font-size: 26px;
}
.status-content {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.success-icon {
  color: #7ed321;
}
.failure-text {
  color: #ed3131;
  font-size: 14px;
  margin-top:5px;
  margin-bottom:5px;
}
.status-icon svg {
  margin: auto;
  display: block;
}
.btn {
  /*height: 43px;*/
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background:  rgba(0, 0, 0, 0.9);
}
.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  padding: 0px 30px;
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}

.modal-body p {
  margin-bottom: 5px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 20px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}
.modal-default-button {
  float: right;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>