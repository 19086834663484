<template>
  <div>
    <div class="row">
      <div class="col-12">        
        <div class="accounts-table-container">
          <v-client-table class="table table-hover" v-model="rows" :columns="columns" :options="options" ref="accountTable">
            <button
              slot="favorite"
              slot-scope="{row, update}"
              class="fav-btn btn-enlarge"
              :class="row.favorite?'fas fa-star':'far fa-star'"
              @click="setFavorite(row); update(row.favorite)"
            />
            <span slot="accountCustomName" slot-scope="props">
              <button title="Open Account Details" class="btn-link" @click="toggleAccountDetails(props.row)">
                <span class="account-text">
                  <span class="child-toggler-icon">
                    <i class="fas fa-angle-down" v-if="detailsDisplayed.includes(props.row.accountId)" ></i> 
                    <i class="fas fa-angle-right" v-else></i> 
                  </span>
                  {{props.row.accountCustomName.toLowerCase() || "-"}}
                </span>
              </button>
            </span>
            <span slot="trust" slot-scope="props">
              <span v-if="props.row.components">
                <button v-if="props.row.components.length === 1" title="Open Service/Trust Details" class="btn-link" @click="toggleAccountDetails(props.row,'Service')">
                  <i v-if="props.row.components[0].trustOutcomeScore === 0" class="fas fa-check-circle" style="color: #2bc275; margin-right: 4px"/>
<!--                  <i v-else-if="props.row.components[0].trustScore > 0 && props.row.components[0].trustScore < 100" class="fas fa-exclamation-circle" style="color: #ffc21a;"/>-->
                  <i v-else-if="props.row.components[0].trustOutcomeScore > 0" class="fas fa-exclamation-circle" style="color: #d74e26; margin-right: 4px"/>
                  <span class="account-text">
                    {{ props.row.components[0].trustOutcomeScore ?? "-"}}
                  </span>
                </button>
                <button v-else-if="props.row.components.length > 1" title="Open Service/Trust Details" class="btn-link" @click="toggleAccountDetails(props.row, 'Service')">
                  <i class="far fa-question-circle" title="Multiple Services exist, click for Service/Trust details"/>
                </button>
              </span>
              <span v-else>
                <button title="Trust Score N/A, click for Service/Trust Details" class="btn-link" @click="toggleAccountDetails(props.row, 'Service')">
                  <span class="account-text">
                    <i class="far fa-question-circle" />
                  </span>
                </button>
              </span>
            </span>
            <span slot="costEfficiency" slot-scope="props">
              <button title="Open Billing Insights" class="btn-link" @click="toggleAccountDetails(props.row, 'Billing')">
                <span class="account-text">
                  {{parseAccountCostEfficiency(props.row)}}
                </span>
              </button>
            </span>
            <span slot="environment" slot-scope="props">
              <span class="available-roles" :title="props.row.environment">{{props.row.environment.toUpperCase()}}</span>
            </span>
            <template v-slot:accountId="props">
              <button class="btn-link" style="cursor: copy" title="Click to copy Id" @click="copyText(props.row.accountId)">
                <span class="account-text">
                  {{props.row.accountId}}
                </span>
              </button>
            </template>
            <span slot="roles" slot-scope="props">
              <span class="available-roles" :title="parseRole(props.row)">{{parseRole(props.row, true)}}</span>
            </span>
            <span slot="action" slot-scope="props">
              <i v-if="loadingAccount===props.row.accountId" class="fas fa-circle-notch fa-spin fa-lg" style="margin-left: 10px; ;margin-right: 6px"></i>
<!--              <InlineLoader v-if="loadingAccount===props.row.accountId"/>-->
              <button v-else title="Launch AWS Console" class="btn-link btn-enlarge" @click="initiateLaunchSequence(props.row)">
                <i class="fab fa-aws fa-lg"/>
              </button>
              <button title="AWS CLI Keys" class="btn-link btn-enlarge" @click="initiateCLILaunchSequence(props.row)">
                <i class="fas fa-code"/>
              </button>
              <button title="Cloud Support Request" class="btn-link btn-enlarge" @click="showGenericCloudSupportRequestModal(props.row)">
                <i class="fas fa-tools"/>
              </button>
              <button v-if="parseRole(props.row).includes('Owner')" title="Account Closure Request" class="btn-link btn-enlarge" @click="showAccountClosureRequestModal(props.row)">
                <i class="fas fa-trash"/>
              </button>
              <em v-if="getHasAdminRole && !hasValidAppId(props.row)" class="fa fa-exclamation app-id-notice" 
                :title="'Invalid App ID ('+props.row.azureAppId+')'"></em>
            </span>
            <template v-slot:child_row="props">
              <account-details
                  :data="props.row"
                  :initial-tab="initialDetailTab"
              />
            </template>
          </v-client-table>
        </div>
      </div>
    </div>
    <transition name="modal">
      <missing-data-modal
        v-if="showMissingDataModal"
        :account="accountDetails"
        @close="closeMissingDataModal"
      />
    </transition>
    <transition name="modal">
      <cli-access-modal
          v-if="showCliAccessModal"
          :requestData="cliRequestData"
          @close="closeCliAccessModal"
      />
    </transition>
    <transition name="modal">
      <generic-cloud-support-request-modal
          v-if="displayGenericCloudSupportRequestModal"
          :account="accountDetails.info"
          @close="closeGenericCloudSupportRequestModal"
      />
    </transition>
    <transition name="modal">
      <account-closure-request-modal
          v-if="displayAccountClosureRequestModal"
          :account="accountDetails.info"
          @close="closeAccountClosureRequestModal"
      />
    </transition>
  </div>
</template>

<script>
import api from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import * as C from "../constants.js";
import AccountDetails from "@/components/AccountDetailsV2.vue";
import MissingDataModal from "@/components/modal/MissingDataModal.vue";
import CliAccessModal from "@/components/modal/CliAccessModal.vue";
import GenericCloudSupportRequestModal from "@/components/modal/GenericCloudSupportRequestModal.vue";
import AccountClosureRequestModal from "@/components/modal/AccountClosureRequestModal.vue";

export default {
  name: "myAccountsTable",
  components: {
    AccountClosureRequestModal,
    GenericCloudSupportRequestModal,
    CliAccessModal,
    AccountDetails,
    MissingDataModal
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
    rows: {
      get: function () {
        return this.getAccounts;
      },
      set: function (newValue) {
        this.setAccounts(newValue);
      },
    }
  },
  data: function () {
    return {
      C,
      accountsFiltered: true,
      columns: ["favorite", "accountCustomName", "trust", "costEfficiency", "environment", "accountId", "roles", "action"],
      options: {
        customFilters: [
          {
            name: "search",
            callback: function (row, query) {
              let q = query.toLowerCase();
              let name = row.accountCustomName.toLowerCase();
              let id = row.accountId.toLowerCase();
              return name.includes(q) || id.includes(q);
            },
          },
        ],
        skin: "table",
        editableColumns: ["favorite"],
        headings: {
          favorite: "*",
          accountCustomName: "Name",
          trust: "Trust Outcomes",
          costEfficiency: "Cost Efficiency",
          environment: "Environment",
          accountId: "Account ID",
          roles: "Active roles",
          action: "Actions",
        },
        uniqueKey: "accountId",
        sortable: ["favorite", "accountCustomName","trust", "costEfficiency", "environment", "accountId"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No corporate accounts to display",
        },
        headingsTooltips: {
          favorite : "Click to sort. Shift+Click to multi-sort.",
          accountId : "Click to sort. Shift+Click to multi-sort.",
          accountCustomName : "Click to sort. Shift+Click to multi-sort.",
          trust: "Trust Issues for the associated service filtered by Trust Outcomes. Click to sort. Shift+Click to multi-sort."
        },
        perPageValues: [10,25,50],
        showChildRowToggler: false
      },
      isLoading: false,
      loadingAccount: null,
      initialDetailTab: "General",
      detailsDisplayed:[],
      accountDetails: {"info": null, "details": null},
      cliRequestData: null,
      showCliAccessModal: null,
      showMissingDataModal: null,
      displayGenericCloudSupportRequestModal: null,
      displayAccountClosureRequestModal: null
    };
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["setAccounts"]),
    ...mapActions(["addAlert", "loadAccounts"]),
    async setFavorite(val) {
      val.favorite = !val.favorite;
      try {
        if (val.favorite) {
          await api.saveMyFavorite({
            accountId: val.accountId,
          });
          this.trackEvent(C.ACTION_TYPES.ADD_FAVORITE, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        } else {
          await api.deleteMyFavorite(val.accountId);
          this.trackEvent(C.ACTION_TYPES.REMOVE_FAVORITE, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${val.accountId} name=${val.accountCustomName}`);
        }
      } catch (err) {
        val.favorite = !val.favorite;
        console.error("Error saving favorite", err);
        this.addAlert(
          util.createAlert(0,
            "Error modifying favorite. Please try again or contact support.",
            C.ALERT_CONTEXT.DANGER
          )
        );
      }
    },
    copyText(text){
      navigator.clipboard.writeText(text);
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    accessActionClick(item) {      
      this.trackEvent(C.ACTION_TYPES.ACCESS_LINK, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${item.accountId} name=${item.accountCustomName}`);
    },
    async initiateLaunchSequence(item) {
      this.loadingAccount = item.accountId
      this.accountDetails.info = item
      try{
        let detailsResponse = await api.getAccountDetails(item.accountId)
        this.accountDetails.details = detailsResponse
        this.showMissingDataModal = !(detailsResponse.data.data.components && detailsResponse.data.data.components.length > 0);
      } catch(err){
        this.addAlert(util.createAlert(0, "Error loading Account Details. Please try again or contact <a href=\"https://autodesk.slack.com/archives/CDYEFBL9L/\">#adsk-cloud-support</a>.", C.ALERT_CONTEXT.DANGER))
      } finally{
        this.loadingAccount = null
      }
      if(this.showMissingDataModal === false){
        window.open(this.parseLoginUrl(item), '_blank');
        this.accessActionClick(item)
      }
    },
    launchConsole(item){
      window.open(this.parseLoginUrl(item), '_blank');
      this.accessActionClick(item)
    },
    initiateCLILaunchSequence(item){
      this.showCliAccessModal = true
      this.cliRequestData = {
        "accountId": item.accountId,
        "accountCustomName": item.accountCustomName,
        "roles": this.parseRole(item).split(', '),
        "username": this.getAppUser.email
      }
      this.trackEvent(C.ACTION_TYPES.CLI_ACCESS, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${item.accountId} name=${item.accountCustomName}`);
    },
    showGenericCloudSupportRequestModal(item) {
      this.trackEvent(C.ACTION_TYPES.NON_STANDARD_REQUEST_LINK, this.$router.currentRoute.path+"?view=my-accounts", `account_id=${item.accountId} name=${item.accountCustomName}`);
      this.displayGenericCloudSupportRequestModal = true
      this.accountDetails.info = item
    },
    showAccountClosureRequestModal(item) {
      this.trackEvent(C.ACTION_TYPES.ACCOUNT_CLOSURE_REQUEST_LINK, this.$router.currentRoute.path+"?view=my-accounts", `account_id=${item.accountId} name=${item.accountCustomName}`);
      this.displayAccountClosureRequestModal = true
      this.accountDetails.info = item
    },
    closeGenericCloudSupportRequestModal(){
      this.displayGenericCloudSupportRequestModal = false
      this.accountDetails.info = null
    },
    closeAccountClosureRequestModal(){
      this.displayAccountClosureRequestModal = false
      this.accountDetails.info = null
    },
    closeCliAccessModal(){
      this.showCliAccessModal = false
    },
    closeMissingDataModal(){
      this.showMissingDataModal = false
      this.accessRequestAccount = null
    },
    parseRole(account, trim) {
      return util.parseRole(account, trim)
    },
    parseLoginUrl(item) {
      return util.parseLoginUrl(item, this.getAppUser.email)
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    parseAccountCostEfficiency(item){
      const percentage = item.billingInsight?.CALC_EFF * 100;
      if(isNaN(percentage)){
        return '-'
      }
      if (percentage % 1 === 0) {
        return percentage.toFixed(0) + "%";
      } else {
        return percentage.toFixed(2) + "%";
      }
    },
    async reloadAccounts() {
      await this.loadAccounts()
    },
    async toggleAccountDetails(row, detailTab) {
      this.initialDetailTab = detailTab ?? "General";

      if (this.detailsDisplayed.includes(row.accountId)) {
        this.detailsDisplayed.splice(this.detailsDisplayed.indexOf(row.accountId), 1)
        this.trackEvent(C.ACTION_TYPES.HIDE_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      } else {
        this.detailsDisplayed.push(row.accountId)
        this.trackEvent(C.ACTION_TYPES.SHOW_VIEW_DETAIL, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${row.accountId} name=${row.accountCustomName}`);
      }

      this.$refs.accountTable.toggleChildRow(row.accountId);
    },
    openTrustOverview(item){
      window.open(`https://trustdashboard.autodesk.com/applications/${item.components[0].id}/outcomes`, '_blank')
      this.trackEvent(C.ACTION_TYPES.TRUST_LINK, this.$router.currentRoute.path, `service_name=${item.componentName}`);
    }
  },
};
</script>
<style scoped>
.account-text{
  color:#5a5a5a;
  display:inline-flex;
}
.child-toggler-icon{
  font-size: 14px;
  margin-right: 8px;
  color:#5f60ff;
}
.cache-info-text {
  font-size: 14px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.accounts-table-container {
  margin-top: 5px;
  font-size: 14px;
}
.access-link {
  font-weight: bold;
}
.app-id-notice {
  color: red;
  font-size: 14px;
}
.sync-btn {
  border: none;
  background: transparent;
  color: #5f60ff;
  outline: none;
  margin-left: 5px;
  font-size: 14px;
}
.sync-btn:hover {
  color: #0056b3;
}
.loading-container {
  margin-top: 50px;
}

.modal-enter-active {
  transition: all .3s ease-in;
}
.modal-leave-active {
  transition: all .3s ease-out;
}
.modal-enter, .modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}

</style>
