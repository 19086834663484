<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">Account Closure Confirmation</strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body" >
            <div class="input-body">
              <div class="row">
                <div class="col-12">
                  <p>You are about to submit a <strong>Account Closure</strong> Request, do you wish to continue?</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="offset-md-4 col-md-4">
                <a class="modal-default-button btn flat-btn blue-btn" 
                  @click="submit()" v-on:click.middle="submit()">
                  Confirm
                </a>
              </div>
              <div class="col-md-4">
                <button class="modal-default-button btn flat-btn" @click="close()">Cancel </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import * as C from "../../constants.js";

export default {
  name: "ConfirmAccountClosureModal",
  props: {
    account: Object,
  },
  components: {
  },
  computed: {
    ...mapGetters(["getAppUser"])
  },
  data: function() {
    return {
      C
    };
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    submit(){
      this.$emit("submit");
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.details{
  margin-bottom: 25px;;
}
.required-text {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
.form-control {
  border-radius: 0px;
}
.modal-wrapper {
  font-size: 15px;
  text-align: center;
  line-height: 18px;
}
.form-group {
  text-align: left;
}
.status-icon {
  font-size: 50px;
  margin-bottom:20px;
}
.status-title {
  color: #575757;
  font-size: 26px;
}
.status-content {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.success-icon {
  color: #7ed321;
}
.failure-text {
  color: #ed3131;
  font-size: 14px;
  margin-top:5px;
  margin-bottom:5px;
}
.status-icon svg {
  margin: auto;
  display: block;
}
.btn {
  /*height: 43px;*/
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background: black;
}
.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  padding: 0px 30px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.modal-body p {
  margin-bottom: 5px;
}
.modal-body strong{
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 20px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}
.modal-default-button {
  float: right;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>