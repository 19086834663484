/* eslint-disable */
import axios from 'axios';
import * as url from "url";

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

const api = {
  async getAllAccounts() {
    return apiInstance.get("/accounts")
  },
  async getMyAccounts() {
    return apiInstance.get("/my/accounts")
  },
  async getMyAccountsCache() {
    return apiInstance.get("/my/accounts/cache")
  },
  async getMyFavorites() {
    return apiInstance.get("/my/favorites")
  },
  async saveMyFavorite(body) {
    return apiInstance.post("/my/favorites", body)
  },
  async deleteMyFavorite(id) {
    return apiInstance.delete("/my/favorites/" + id)
  },
  async getNotifications(body) {
    return apiInstance.post("/notifications/query", body)
  },
  async getAccountDetails(id) {
    return apiInstance.get("/accounts/" + id)
  },
  async saveUserAction(body) {
    return apiInstance.post("/actions", body)
  },
  async submitAccessRequest(body) {
    return apiInstance.post("/accessRequest", body)
  },
  async submitNonStandardAWSRequest(body) {
    return apiInstance.post("/cloudSupport/nonStandard", body)
  },
  async submitAccountClosureRequest(body) {
    return apiInstance.post("/cloudSupport/accountClosure", body)
  },
  async getWhatsNew(){
    return apiInstance.get("/whatsNew")
  },
  async getAccountAzureInfo(id, filter){
    return apiInstance.get("/accounts/info/" + id + `?profile=${filter.profile}&env=${filter.env}&role=${filter.role}`)
  },
  async getServiceHealth(){
    return apiInstance.get("/health")
  },
  getApiInstance() {
    return apiInstance;
  },
  async getCLIAccessKeys(accountId, username, role, ttl) {
    return axios.put(
        `${process.env.VUE_APP_STS_PROXY_URL}/fetch-sts?accountID=${accountId}&username=${username}&role=${role}&ttl=${ttl}`,
        null,
        {
          headers: {
            'Authorization': apiInstance.defaults.headers.common.Authorization,
          }
        }
    )
  }
};

export default api;