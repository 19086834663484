<template>
  <div id="app">
    <help-hub-header
      :env="currentEnv"
      @onHHComponentSuccess="onHHComponentSuccess"
      @onHHComponentError="onHHComponentError"
      :title="pageTitle"
      :logMixpanel="currentEnv === 'DEV' || currentEnv === 'LOCAL'"
      :router="$router"
    />
    <div v-if="headerLoaded">
      <div class="outage-alert-container" v-if="(this.getHealthReport.status === 'DOWN' || this.getHealthReport.status === 'MAINTENANCE')">
        <div class="container">
          <b> <i class="fas fa-exclamation-triangle fa-lg"/> </b>
          <span> Portal Services Impacted: </span>
          <span v-for="detail in this.getHealthReport.details" :key="detail.message">{{detail.message}}</span>
        </div>
      </div>
      <div class="title-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="app-page-title"><i class="fas far fa-cloud"></i> {{pageTitle}}</h1>
              <employee-hub-bookmark :title="pageTitle" show-circle :blue-bookmark="true" class="ml-2"/>
            </div>
            <div class="col-12">
              <p>Welcome {{(this.getAppUser.firstName ?? "") +" "+ (this.getAppUser.lastName ?? "")}} ! Please select the account you want to use for this session.</p>
            </div>
            <div class="col-5 search-container">
              <search-filter ref="searchFilter" :route="this.$router.currentRoute.name" />
            </div>
            <div class="col-2" style="padding-left: 0">
              <strong v-if="getCacheDataDate">
                <a
                    title="Refresh"
                    class="btn"
                    style="color: white; padding: 6px 0"
                    @click="reloadAccounts()"
                ><i class="fas fa-sync-alt"/> <em class="cache-info-text" > {{relativeTime}}</em></a>
              </strong>
            </div>
            <div class="offset-3 col-2  justify-content-end">
              <button class="btn btn-enlarge" style="color: white" title="What's New" @click="openWhatsNewModal">
                <i class="fas fa-bullhorn"/>
                <span class="notification-dot"></span>
              </button>
              <button class="btn btn-enlarge" style="color: white" title="FAQs" @click="openFAQ"><i class="fas fa-question"></i></button>
              <button class="btn btn-enlarge" style="color: white" title="#adsk-cloud-support" @click="openSlackSupport"><i class="fab fa-slack"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-wrapper">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              ref="navbarToggler"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav nav-fill w-100 nav-tabs">
                <li class="nav-item">
                  <router-link @click.native="clickRoute('corporate-dashboard')" class="nav-link" to="/corporate-dashboard">
                    <p class="nav-text">Corporate ({{getAccounts.length}})</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link @click.native="clickRoute('commercial-dashboard')" class="nav-link" to="/commercial-dashboard">
                    <p class="nav-text">Commercial ({{getCommercialAccounts.length}})</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link @click.native="clickRoute('fedramp-dashboard')" class="nav-link" to="/fedramp-dashboard">
                    <p class="nav-text">FedRAMP ({{getFedrampAccounts.length}})</p>
                  </router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link @click.native="clickRoute()" class="nav-link" to="/personalize">
                    <p class="nav-text">Personalize</p>
                  </router-link>
                </li> -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="content-wrapper" v-if="!getIsLoading">
        <alert-container></alert-container>     
        <!-- <notification-container></notification-container>       -->
        <router-view />
      </div>
      <div class="loading-container" v-else>
        <loading-spinner  />
      </div>
      <div class="footer">
        <help-hub-footer :env="currentEnv"></help-hub-footer>
      </div>
    </div>
    <transition name="whats-new-modal">
      <whats-new-modal
        v-if="showWhatsNewModal"
        @close="closeWhatsNewModal"
      />
    </transition>
  </div>
</template>
<script>
import Vue from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SearchFilter from "@/components/SearchFilter";
import LoadingSpinner from "@/components/LoadingSpinner";
import AlertContainer from "@/components/AlertContainer";
import NotificationContainer from "@/components/NotificationContainer";
import api from "@/api";
import jwt_decode from "jwt-decode";
import * as C from './constants'
import util from "@/util";
import WhatsNewModal from "@/components/modal/WhatsNewModal.vue";

export default {
  data() {
    return {
      C,
      currentEnv: process.env.VUE_APP_ENV,
      headerLoaded: false,
      authToken : undefined,
      pageTitle: "Cloud Login Portal",
      relativeTime: "",
      relativeTimeInterval : null,
      showWhatsNewModal: false
    };
  },
  created() {
    this.addAlert(
        util.createAlert(0,
            C.VPN_WARNING,
            C.ALERT_CONTEXT.INFO
        )
    );
  },
  mounted() {
    this.refreshRelativeTime()
    this.relativeTimeInterval = setInterval(()=>{
      this.refreshRelativeTime()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.relativeTimeInterval)
  },
  computed: {
    ...mapGetters([
      "getIsLoading",
      "getAccounts",
      "getCommercialAccounts",
      "getFedrampAccounts",
      "getAppUser",
      "getHealthReport",
      "getCacheDataDate"
    ])
  },
  components: {
    WhatsNewModal,
    SearchFilter,
    LoadingSpinner,
    AlertContainer,
    NotificationContainer
  },
  methods: {
    ...mapMutations(["setAppUser", "setTokenData"]),
    ...mapActions(['loadAccounts', 'addAlert']),
    clickRoute(name) {
      if (window.innerWidth < 992) {
        this.$refs.navbarToggler.click();
      }
      this.$refs.searchFilter.clearSearch()
      this.trackEvent(C.ACTION_TYPES.VIEW_DASHBOARD, this.$router.currentRoute.path, name); 
    },
    onHHComponentSuccess(response) {
      response = response.detail[0];
      this.authToken = response.accessToken
      api.getApiInstance().defaults.headers.common.Authorization =
        "Bearer " + response.accessToken;
        
      api.getApiInstance().defaults.headers.common.azuretoken =
        "Bearer " + response.user.azureAccessToken;
      let decoded = jwt_decode(response.user.azureAccessToken);
      this.setTokenData(decoded)
      if (!this.headerLoaded) {
        let user = response.user;
        this.setAppUser(user);
        this.$store.dispatch("initialize");

        this.headerLoaded = true;
        // mixpanel tracking
        var mixpanelInstance = response.mixpanelInstance;
        mixpanelInstance.addRouterAutoTracking(this.$router);
        Vue.prototype.$hhMixpanel = mixpanelInstance;
      }
    },
    onHHComponentError(error) {
      console.log("Help Hub Header Error : ", error);
    },
    refreshRelativeTime(){
      this.relativeTime = util.parseRelativeTime(this.getCacheDataDate)
    },
    async reloadAccounts() {
      await this.loadAccounts()
          .then(()=>this.refreshRelativeTime())
      this.trackEvent(C.ACTION_TYPES.REFRESH_DASHBOARD, this.$router.currentRoute.path);
    },
    openFAQ(){
      this.trackEvent(C.ACTION_TYPES.FAQ_CLICK, this.$router.currentRoute.path);
      window.open(C.FAQ_LINK, '_blank')
    },
    openSlackSupport(){
      this.trackEvent(C.ACTION_TYPES.SLACK_SUPPORT_CLICK, this.$router.currentRoute.path);
      window.open(C.SLACK_SUPPORT_LINK, '_blank')
    },
    openWhatsNewModal(){
      this.showWhatsNewModal = true
      this.trackEvent(C.ACTION_TYPES.WHATS_NEW_MODAL_OPEN, this.$router.currentRoute.path);
    },
    closeWhatsNewModal(){
      this.showWhatsNewModal = false
    }
  },
};
</script>
<style scoped>
.app-page-title{
  float:left;
  margin-right: 10px;
  font-size: xx-large;
  font-weight: bold;
}

.outage-alert-container{
  background-color: #ffc21a;
  color: black;
  line-height: 1.5;
  padding: 12px;
}

@media (min-width: 1800px) {
  .title-wrapper {
    background-image: url("assets/images/banner-bg.jpg");
    background-size: cover;
    color: #ffffff;
    padding-top: 20px;
  }
}
@media (max-width: 1800px) {
  .title-wrapper {
    background-image: url("assets/images/banner-bg.jpg");
    background-size: cover;
    color: #ffffff;
    padding-top: 20px;
  }
}
.title-wrapper h1 {
  margin-bottom: 5px;
}
.title-wrapper p {
  font-size: 14px;
}
.content-wrapper {
  background-color: #ffffff;
  margin-bottom: 20px;
}
.loading-container {
  margin-top: 50px;
}
.footer {
  margin-top: 50px;
}
.cache-info-text {
  font-size: 10px;
}
.notification-dot {
  position: absolute;
  top: 5px;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}

/** Navigation **/
.nav-wrapper {
  background-color: #f4f6f8;
}
.navbar-toggler {
  margin: 15px;
}
.navbar {
  padding: 0;
}
.navbar a {
  font-weight: bold;
  color: #575757;
}
.nav-tabs {
  border-bottom: none;
}
.nav-item .nav-text {
  font-size: 14px;
  margin-bottom: 5px;
}
.nav-item .nav-icon {
  font-size: 16px;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.router-link-active {
  background-color: #ffffff;
  color: #5F60FF !important;
  border-bottom-color: transparent !important;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .title-container {
    padding-left: 58px !important;
    margin-left: 0px;
  }
}
.whats-new-modal-enter-active {
  transition: all .3s ease-in;
}
.whats-new-modal-leave-active {
  transition: all .3s ease-out;
}
.whats-new-modal-enter, .whats-new-modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
</style>
