<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div v-bind:class="{'modal-container-big': isSuccess, 'modal-container': !isSuccess}">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">CLI Credentials</strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="!isLoading">
            <div class="input-body">
              <div class="row" v-if="isSuccess">
                <div class="col-lg-12">
                <ul class="tabs">
                  <li :class="{ 'tab': true, 'active': selectedTab === 'bash' }" @click="changeKeyFormat('bash')">Linux or macOS (bash)</li>
                  <li :class="{ 'tab': true, 'active': selectedTab === 'fish' }" @click="changeKeyFormat('fish')">Linux or macOS (fish)</li>
                  <li :class="{ 'tab': true, 'active': selectedTab === 'powershell' }" @click="changeKeyFormat('powershell')">Windows (Powershell)</li>
                  <li :class="{ 'tab': true, 'active': selectedTab === 'cmd' }" style="padding-right: 30px; padding-left: 20px" @click="changeKeyFormat('cmd')">Windows (cmd)</li>
                </ul>
                </div>
                <div class="col-lg-12">
                  <p class="status-content" :class="{ textshadow : isBlurred}" @click="toggleBlur()">
                    {{formattedCredentials}}
                  </p>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-12">
                  <div v-if="isFailure" class="alert alert-danger" role="alert">
                    <span>
                      An error has occurred while submitting your request. Please try again. If the problem persists, please contact support.
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  Please fill the required details to fetch CLI access keys for the following AWS Account
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label >Account name</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="requestData.accountCustomName" />
                  </div>
                  <div class="form-group">
                    <label >Account ID</label>
                    <input type="text" class="form-control" disabled="disabled" v-model="requestData.accountId" />
                  </div>
                  <div class="form-group">
                    <label >Requested role * </label>
                    <select class="form-control" v-model="requestedRole">
                      <option v-for="option in requestData.roles" :key="option">{{option}}</option>
                    </select>
                    <em v-if="requestedRoleError" class="required-text">* This is a required field.</em>
                  </div>
                  <div class="form-group">
                    <label >TTL * </label>
                    <select class="form-control" v-model="ttl">
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="60">60 minutes</option>
                    </select>
                    <em v-if="ttlError" class="required-text">* TTL is required.</em>
                  </div>
                  <div class="form-group">
                    <label >Region * </label>
                    <select class="form-control" v-model="region">
                      <option value="us-east-1">us-east-1</option>
                      <option value="us-west-2">us-west-2</option>
                      <option value="eu-west-1">eu-west-1</option>
                    </select>
                    <em v-if="ttlError" class="required-text">* TTL is required.</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else >
            <div class="col-12 modal-body">
              <p>Fetching access cli credentials. This will just take a moment. Please wait...</p>
            </div>
            <div class="col-12">
              <loading-spinner class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="offset-md-4 col-md-4">
                <button class="modal-default-button btn flat-btn blue-btn" @click="fetchKeys()" v-if="!isLoading && !isSuccess" >Fetch</button>
              </div>
              <div class="offset-md-1 col-md-5">
                <button class="modal-default-button btn flat-btn blue-btn" @click="copyKeys()" v-if="!isLoading && isSuccess"> <i class="fas fa-copy"/> {{ isCopied ? ' Copied ' : ' Copy '}} </button>
              </div>
              <div class="col-md-5">
                <button class="modal-default-button btn flat-btn blue-btn" @click="downloadKeys()" v-if="!isLoading && isSuccess"><i class="fas fa-solid fa-download"/> Download </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import api from "@/api";
import util from "@/util";
import * as C from "../../constants.js";
import LoadingSpinner from "../LoadingSpinner.vue";

export default {
  name: "CliAccessModal",
  props: {
    requestData: Object
  },
  components: {
    LoadingSpinner
  },
  computed: {
    ...mapGetters([""])
  },
  data: function() {
    return {
      C,
      isLoading: false,
      isFailure: false,
      isSuccess: false,
      isBlurred: false,
      isCopied: false,
      requestedRoleError: false,
      ttlError: false,
      requestedRole: this.requestData.roles[0],
      ttl:"15",
      region:'us-east-1',
      responseKeys: null,
      selectedTab: "bash",
      formattedCredentials: null,
    };
  },
  mounted() {
    console.log(this.requestData)
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    async fetchKeys() {
      if (util.stringIsBlank(this.requestedRole)) {
        this.requestedRoleError = true
        return
      }
      if (util.stringIsBlank(this.ttl)) {
        this.ttlError = true
        return
      }

      this.isLoading = true
      this.trackEvent(C.ACTION_TYPES.FETCH_CLI_CREDENTIALS, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${this.requestData.accountId} name=${this.requestData.accountCustomName}`);
      try {
        this.responseKeys = await api.getCLIAccessKeys(this.requestData.accountId, this.requestData.username, this.requestedRole, this.ttl )
        this.changeKeyFormat('bash')
        this.isSuccess = true;
        this.isLoading = false
        this.trackEvent(C.ACTION_TYPES.CLI_ACCESS_REQUEST_SUCCESS, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${this.requestData.accountId} name=${this.requestData.accountCustomName}`);
      } catch (err) {
        console.error("CLI Access request error", err)
        this.isFailure = true;
        this.isLoading = false
        this.trackEvent(C.ACTION_TYPES.CLI_ACCESS_REQUEST_FAILURE, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${this.requestData.accountId} name=${this.requestData.accountCustomName}`);
      }
    },
    changeKeyFormat(tab){
      this.selectedTab = tab
      this.isCopied = false
      switch (tab) {
        case 'bash':
          this.formattedCredentials = 'export AWS_DEFAULT_REGION="' + this.region + '"' + '\n' +
              'export AWS_ACCESS_KEY_ID="' + this.responseKeys.data.AWS_ACCESS_KEY_ID + '"' +'\n' +
            'export AWS_SECRET_ACCESS_KEY="' + this.responseKeys.data.AWS_SECRET_ACCESS_KEY + '"' +'\n' +
            'export AWS_SESSION_TOKEN="' + this.responseKeys.data.AWS_SESSION_TOKEN + '"' +'\n'
          break;
        case 'fish':
          this.formattedCredentials = 'set -x AWS_DEFAULT_REGION "' + this.region + '"' + '\n' +
              'set -x AWS_ACCESS_KEY_ID "' + this.responseKeys.data.AWS_ACCESS_KEY_ID + '"' +'\n' +
              'set -x AWS_SECRET_ACCESS_KEY "' + this.responseKeys.data.AWS_SECRET_ACCESS_KEY + '"' + '\n' +
              'set -x AWS_SESSION_TOKEN "' + this.responseKeys.data.AWS_SESSION_TOKEN + '"' +'\n'
          break;
        case 'powershell':
          this.formattedCredentials = '$env:AWS_DEFAULT_REGION="' + this.region + '"' +'\n' +
              '$env:AWS_ACCESS_KEY_ID="' + this.responseKeys.data.AWS_ACCESS_KEY_ID + '"' + '\n' +
              '$env:AWS_SECRET_ACCESS_KEY="' + this.responseKeys.data.AWS_SECRET_ACCESS_KEY + '"' + '\n' +
              '$env:AWS_SESSION_TOKEN="' + this.responseKeys.data.AWS_SESSION_TOKEN + '"' + '\n'
          break;
        case 'cmd':
          this.formattedCredentials = 'set AWS_DEFAULT_REGION=' + this.region + '\n' +
              'set AWS_ACCESS_KEY_ID=' + this.responseKeys.data.AWS_ACCESS_KEY_ID + '\n' +
              'set AWS_SECRET_ACCESS_KEY=' + this.responseKeys.data.AWS_SECRET_ACCESS_KEY + '\n' +
              'set AWS_SESSION_TOKEN=' + this.responseKeys.data.AWS_SESSION_TOKEN + '\n'
          break;
      }
    },
    toggleBlur(){
      this.isBlurred = !this.isBlurred
    },
    copyKeys(){
      navigator.clipboard.writeText(this.formattedCredentials);
      this.isCopied = true
    },
    downloadKeys(){
      const link = document.createElement("a");
      const file = new Blob([ this.responseKeys.data.CREDENTIALS], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = `ADSK_Credentials_${this.requestData.accountId}.txt`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }
};
</script>

<style scoped>
.required-text {
  width: 100%;
  margin-top: .25rem;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
  font-size: 80%;
  color: #dc3545;
}
.form-control {
  border-radius: 4px;
}
.modal-wrapper {
  text-align: center;
  line-height: 18px;
}
.form-group {
  text-align: left;
}

.status-title {
  color: #575757;
  font-size: 18px;
}

.status-content {
  font-size: 12px;
  margin-top: 10px;
  text-align: start;
  overflow-wrap: break-word;
  background-color: #f1f1f1;
  padding: 10px;
  white-space: pre-line;
  border: 0.1px solid darkgray;
  border-radius: 2px;
}

.failure-text {
  color: #ed3131;
  font-size: 14px;
  margin-top:5px;
  margin-bottom:5px;
}

.textshadow {
  color: transparent;
  text-shadow: 0 0 4px rgba(0,0,0,0.5);
}

.status-icon svg {
  margin: auto;
  display: block;
}
.btn {
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}
.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background:  rgba(0, 0, 0, 0.9);
}
.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  padding: 0px 30px;
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}

.modal-body p {
  margin-bottom: 5px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 20px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container-big {
  width: 750px;
  height: 400px;
  margin: 0px auto;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}
.modal-default-button {
  float: right;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab {
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
}

.tab.active {
  background-color: #5f60ff;
  color: white;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>