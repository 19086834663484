<template>
  <div class="VueTables__limit-field per-page-container">
    <p class="per-page-text">Rows per page </p>
    <select
      :id="props.selectAttrs.id"
      :class="'per-page-select'"
      :value="props.selectAttrs.value"
      @change="props.selectEvents.change">
      <option v-for="val in props.perPageValues" :value="val" :key="val">{{val}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "PerPageSelector",
  props: ["props"]
};
</script>
<style scoped>
.per-page-container {
  width: 150px;
}
select {
  border: none;
  border-radius: 0;
  background: #eceff3;
  font-weight: bold;
}
.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.per-page-select{
  display: inline;
}
.per-page-text {
  display: inline;
  font-size: 12px;
  color: #9fa7af;
  margin-right: 10px;
}
</style>