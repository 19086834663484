export const CURRENT_ENV = process.env.VUE_APP_ENV
export const IS_PROD_ENV = process.env.VUE_APP_ENV === "TEST" || process.env.VUE_APP_ENV === "PRD"
export const LOGIN_URL = "https://account.activedirectory.windowsazure.com/applications/signin"

export const INCONTACT_CHAT_LINK="https://home-c6.incontact.com/inContact/ChatClient/ChatClientPatron.aspx?poc=4f93fa0f-21e1-46ce-a233-3d03ea0bfd69&bu=4593230"
export const FAQ_LINK="https://one.autodesk.com/IT/articles/fff1c7f51bdef4501e13a931604bcb32"
export const SLACK_SUPPORT_LINK="https://autodesk.slack.com/archives/CDYEFBL9L"
export const COMMERCIAL_ACCOUNT_CLOSURE_URL="https://autodeskcloudops.servicenowservices.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D1efbe9914fbadf00907685c98310c770%26sysparm_link_parent%3De64bea352bade200056069a219da1588%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default%26sysparm_view%3Dcatalog_default"
export const SERVICE_NOW_URL = process.env.VUE_APP_SNOW_BASE_URL

export const GLOBAL_ADMINS_ROLE = "SG-AWS-Global-Admins" 
export const NETWORK_ADMINS_ROLE = "SG-AWS-Network-Admins"
export const SECURITY_AUDIT_ROLE = "SG-AWS-Security-Audit"

export const VPN_WARNING = "Note :- All actions on AWS Console / CLI will only be allowed if connected to the respective Corp/Commercial/FedRAMP VPN. For additional info <a target='_blank' href='https://wiki.autodesk.com/x/ylY2aQ'>Click Here</a>, For support, reach us at <a target='_blank' href='https://autodesk.slack.com/archives/CDYEFBL9L'>#adsk-cloud-support</a>."
export const WOR_MESSAGE = "<b>Action Required:</b> Get ready for the upcoming week of rest holidays by taking action to assess and optimize your cloud infrastructure utilization. Make sure to stop any unused resources, such as EC2 and RDS instances, leverage the  <a target='_blank' href='https://one.autodesk.com/apps/cloud-smart-scheduler/'>Cloud Smart Scheduler</a> to manage your non-prod resource schedules."
export const JIT_BANNER = "The new Just-in-time (JIT) process is now live, and to access FedRAMP AWS Prod, please follow the JIT process. Which means there wouldn’t be any standing/long-term access. Teams would need to follow the - <a target='_blank' href='https://wiki.autodesk.com/display/IMD/JIT+Steps'>JIT process</a>. If you have questions or require help, post in <a target='_blank' href='https://autodesk.slack.com/archives/C04PG7T8TNV'>#fedramp-enhanced-trust</a> on Slack."
export const FEDRAMP_BANNER = "<strong><u>Important Announcement:</u></strong><br>" +
    "</ul><li>June 30, 2024 – All access from China to FedRAMP AWS Production will be removed.</li>" +
    "<li>July 31, 2024 – Access to FedRAMP AWS Production will be limited to the small group of users with Privileged Access to FedRAMP, using FedRAMP Laptops, Just-in-Time (JIT) access, and YubiKeys. All other access to FedRAMP AWS Production will be removed.</li></ul>" +
    "For more information, please review the <a target='_blank' href='https://one.autodesk.com/IT/articles/f069d864974d35506d3670021153af3d'>FedRAMP FAQs</a>, and these wiki pages for <a target='_blank' href='https://wiki.autodesk.com/display/IMD/Just-in-time+Access'>JIT access</a> and <a target='_blank' href='https://wiki.autodesk.com/display/IMD/JIT+Steps'>setup steps</a>. If you have questions, please reach out to the program <a href='mailto:FedRAMP.EnhancedTrust.CoreTeam@autodesk.com'>Core Team</a>, or post in the <a target='_blank' href='https://autodesk.slack.com/archives/C04PG7T8TNV'>#security-fedramp</a> Slack channel."
export const ACCOUNTS_UPDATED_MSG = "The list of accounts you are viewing is out of date. An updated list is available to you. <RELOAD_LINK/>"
export const ERROR_MSGS = {
  GENERIC_ERROR: 'There was an error that wasn’t your fault. Please try again later or contact support.',
  ACCOUNTS_ERROR: 'An error occurred while retrieving accounts. Please try again later or contact support.',
  ALL_CORP_ACCOUNTS_ERROR: 'An error occurred and we were unable to load all corporate accounts. Please try again later or contact support.',
  ACCOUNTS_CACHE_ERROR : `There was an error loading accounts, and no cached data was available for user. 
                          Please try again later or contact support. If this error persists and immediate 
                          access is required, please search for your AWS account ID in the 
                          <a href="https://myapplications.microsoft.com/">following portal</a>.`,
  FAVORITES_ERROR : "An error occurred while loading favorites. Please try again or contact support.",
  WHATSNEW_ERROR : "An error occurred while loading What's New. Please try again or contact support.",

}

// Alert types
export const ALERT_CONTEXT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark'
}

export const ACTION_TYPES = {
  ACCESS_LINK: 'access_account_link',
  CLI_ACCESS: 'request_cli_access',
  FETCH_CLI_CREDENTIALS: 'fetch_cli_credentials',
  CLI_ACCESS_REQUEST_SUCCESS: 'cli_access_request_success',
  CLI_ACCESS_REQUEST_FAILURE: 'cli_access_request_failure',
  SHOW_VIEW_DETAIL: 'show_view_detail',
  HIDE_VIEW_DETAIL: 'hide_view_detail',
  VIEW_FILTER: 'view_filter',
  ADD_FAVORITE: 'add_favorite',
  REMOVE_FAVORITE: 'remove_favorite',
  ACCESS_FEDRAMP_MODAL_LINK: 'access_fedramp_modal_link',
  ACCESS_FEDRAMP_MODAL_CANCEL: 'access_fedramp_modal_cancel',
  ACCESS_FEDRAMP_MODAL_CONFIRM: 'access_fedramp_modal_confirm',
  ACCESS_REQUEST_LINK: 'access_request_link',  
  COMMERCIAL_ACCESS_REQUEST_LINK: 'commercial_access_request_link',
  FEDRAMP_ACCESS_REQUEST_LINK: 'fedramp_access_request_link',
  ACCESS_REQUEST_SUBMIT: 'access_request_submit',
  ACCESS_REQUEST_SUCCESS: 'access_request_success',
  ACCESS_REQUEST_FAILURE: 'access_request_failure',
  ACCOUNT_CLOSURE_REQUEST_LINK: 'account_closure_request_link',
  ACCOUNT_CLOSURE_REQUEST_SUBMIT: 'account_closure_request_submit',
  ACCOUNT_CLOSURE_REQUEST_SUCCESS: 'account_closure_request_success',
  ACCOUNT_CLOSURE_REQUEST_FAILURE: 'account_closure_request_failure',
  NON_STANDARD_REQUEST_LINK: 'non_standard_request_link',
  NON_STANDARD_REQUEST_SUBMIT: 'non_standard_request_submit',
  NON_STANDARD_REQUEST_SUCCESS: 'non_standard_request_success',
  NON_STANDARD_REQUEST_FAILURE: 'non_standard_request_failure',
  MISSING_DATA_MODAL_SHOW: 'missing_data_modal_show',
  VIEW_DASHBOARD: 'view_dashboard',
  REFRESH_DASHBOARD: 'refresh_dashboard',
  WHATS_NEW_MODAL_OPEN: 'whats_new_modal_opened',
  SLACK_SUPPORT_CLICK: 'slack_support_link_clicked',
  FAQ_CLICK: 'faq_link_clicked',
  RAISE_PIM_LINK: 'raise_pim_link',
  GENERAL_PANE_VIEW: 'view_general_pane',
  OWNERSHIP_PANE_VIEW: 'view_ownership_pane',
  BILLING_PANE_VIEW: 'view_billing_pane',
  SERVICE_PANE_VIEW: 'view_service_pane',
  TRUST_LINK: 'trust_overview_link'
}