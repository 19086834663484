<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text">Missing ServiceId in Service Registry </strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="text-group">
              <p>Account : {{account.details.data.data.accountId}} | {{account.details.data.data.accountCustomName}} </p>
            </div>
            <p>
              <strong><i class="fas fa-exclamation-triangle"/> Attention: </strong>
              <span style="font-size: 12px">
                This AWS account is missing ServiceID in the
                <a class="access-link" :href="parseRedirectUrl(account.details.data.data.accountCustomName)" target="_blank">Service Registry.</a>
                <br>
                Please Update now to avoid AWS Console Login denial for all users after Nov 1, 2023. Instructions
                <a class="access-link" href="https://wiki.autodesk.com/display/DES/Service+Registry+-+How+to+link+Service+Component+to+AWS+Account" target="_blank">here.</a>
                <br>
                <span style="margin-top: 4px">[{{parseRelativeTimeLeft(1698796800000)}}]</span>
              </span>
            </p>
            </div>
          <div class="modal-footer">
            <button class=" btn flat-btn blue-btn" @click="accessAccount(account.info)" :disabled="checkDeadlineEnd()" >Launch for now</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as C from "../../constants.js";
import util from "@/util";
import moment from "moment";

export default {
  name: "MissingDataModal",
  props: {
    account: Object
  },
  components: {},
  computed: {
    ...mapGetters(["getAppUser",
      "getAccounts",
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
  },
  data: function() {
    return {
      C,
      isLoading: false
    };
  },
  mounted() {
    this.trackEvent(C.ACTION_TYPES.MISSING_DATA_MODAL_SHOW, this.$router.currentRoute.path, `account_id=${this.account.details.data.data.accountId} name=${this.account.details.data.data.accountCustomName}`)
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    parseDate(date) {
      return util.parseDateTime(date, "ll")
    },
    parseRelativeTimeLeft(date) {
      const dateNow = moment.now()
      const deadLineDate = moment(date)
      const diffTimeMs = deadLineDate - dateNow
      const absDiffTimeMs = Math.abs(diffTimeMs)
      const diffDays = Math.ceil(absDiffTimeMs / (1000 * 60 * 60 * 24));
      let result = ""
      if(diffDays>1){
        result = diffDays + " days"
      }else{
        result = diffDays + " day"
      }
      if(diffTimeMs>0){
        result += " left"
      } else {
        result += " overdue"
      }
      return result
    },
    parseRedirectUrl(accountCustomName){
      return "https://employeehub.autodesk.com/apps/service-registry/assets/accounts?search=" + accountCustomName
    },
    parseLoginUrl(item) {
      return util.parseLoginUrl(item, this.getAppUser.email)
    },
    checkDeadlineEnd(){
      return moment.now() > 1698796800000 // 1st Nov, 2023 00:00 GMT
    },
    accessAccount(item){
      window.open(this.parseLoginUrl(item), '_blank');
      this.$emit("close");
      this.trackEvent(C.ACTION_TYPES.ACCESS_LINK, this.$router.currentRoute.path+"?view=my-accounts",  `account_id=${this.account.info.accountId} name=${this.account.info.accountCustomName}`);
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  text-align: center;
  line-height: 18px;
}
.text-group {
  text-align: center;
}

.status-icon svg {
  margin: auto;
  display: block;
}

.btn {
  /*height: 43px;*/
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}

.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background: black;
}

.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  padding: 0 4px;
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}
.modal-body p {
  margin-bottom: 8px;
}

.modal-body strong {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 12px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}

.modal-default-button {
  float: none;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.access-link {
  font-weight: bold;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 500px) {
  .modal-container {
    width: 100%;
  }
}
</style>