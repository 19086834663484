<template>
  <div class="loading-container" >
    <img src="../assets/images/iris_spinner.gif" alt="Loading spinner">
  </div>
</template>
<script>
export default {
  name: 'LoadingSpinner',
  components: {},
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<style scoped>
.loading-container img{
  margin: auto;
  display: block;
  height: 70px;
  width: 70px;
}
</style>
