<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="close close-btn" @click="close()">
              <i class="fas fa-times"></i>
            </button>
            <div class="row no-gutters">
              <div class="col-12">
                <p><strong class="title-text"><i class="fas fa-bullhorn" style="padding-right: 8px"/> Whats New? </strong></p>
              </div>
            </div>
          </div>
          <div class="modal-body" style="text-align: center" v-if="this.getsWhatsNew.length === 0">Nothing to show right now</div>
          <div class="modal-body" v-if="!isLoading">
            <template v-for="item in this.getsWhatsNew">
              <div :key="item.id">
                <p><strong> {{item.title}} </strong> - <i>released on {{parseDate(item.created)}}</i></p>
                <div v-if="Array.isArray(item.description)">
                  <ul>
                    <li v-for="note in item.description">
                      {{ note }}
                    </li>
                  </ul>
                </div>
                <div v-else style="padding-right: 8px; padding-left: 30px" >
                  <p>{{item.description}}</p>
                  <p v-if="item.link">For more info, click <a :href="item.link" target="_blank">here</a>.</p>
                </div>
                <hr>
              </div>
            </template>
          </div>
          <div class="row" v-else >
            <div class="col-12">
              <loading-spinner class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as C from "../../constants.js";
import util from "@/util";
import moment from "moment";
import api from "@/api";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "WhatsNewModal",
  components: {LoadingSpinner},
  computed: {
    ...mapGetters(["getAppUser","getsWhatsNew"]),
  },
  data: function() {
    return {
      C,
      isLoading: false,
      whatsNew: {}
    };
  },
  async created() {
    // this.isLoading = true
    // try {
    //   let response = await api.getWhatsNew()
    //   this.whatsNew = response.data.data
    // } catch (err) {
    //   this.addAlert(
    //       util.createAlert(0, "Error fetching Whats New. Please try again or contact support.", C.ALERT_CONTEXT.DANGER)
    //   );
    //   close()
    // }
    // this.isLoading = false;
  },
  methods: {
    ...mapActions(["addAlert"]),
    close() {
      this.$emit("close");
    },
    parseDate(date) {
      return util.parseDateTime(date, "ll")
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  text-align: left;
  line-height: 18px;
}
.text-group {
  text-align: center;
}

.status-icon svg {
  margin: auto;
  display: block;
}

.btn {
  /*height: 43px;*/
  border-radius: 15px;
  font-family: artifaktElementRegular, Arial, Helvetica, sans-serif;
}

.close-btn {
  font-size: 18px;
  color: white;
}

.close-btn:hover {
  color: white;
}

.modal-header {
  display: block;
  padding: 16px 10px 0;
  margin-bottom: 10px;
  background: black;
  position: sticky;
  top: 0;
  z-index: 1;
}

.title-text {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: white;
}

.modal-body {
  /*padding: 4px;*/
  font-family: artifaktElementMedium, Arial, Helvetica, sans-serif;
}
.modal-body p {
  margin-bottom: 8px;
}

.modal-body strong {
  font-family: artifaktElementBold, Arial, Helvetica, sans-serif;
}

.modal-footer {
  margin-top: 12px;
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 750px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px auto;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-footer {
  display: block;
  border-top: none;
}

.modal-default-button {
  float: none;
  padding: 10px 30px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.access-link {
  font-weight: bold;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .modal-default-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 750px) {
  .modal-container {
    width: 100%;
  }
}
</style>
