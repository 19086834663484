<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isLoading" class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light pt-1 pb-3 p-0">
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav nav-fill w-100 nav-tabs">
              <li class="nav-item nav-link" :class="{ 'active': currentTab === 'General' }">
                <button class="nav-text btn btn-link" @click="changeCurrentTab('General')">General</button>
              </li>
              <li class="nav-item nav-link" :class="{ 'active': currentTab === 'Ownership' }">
                <button class="nav-text btn btn-link" @click="changeCurrentTab('Ownership')">Ownership</button>
              </li>
              <li class="nav-item nav-link" :class="{ 'active': currentTab === 'Billing' }">
                <button class="nav-text btn btn-link" @click="changeCurrentTab('Billing')">Billing Insights</button>
              </li>
              <li class="nav-item nav-link" :class="{ 'active': currentTab === 'Service' }">
                <button class="nav-text btn btn-link" @click="changeCurrentTab('Service')">Service/Trust</button>
              </li>
            </ul>
          </div>
        </nav>
        <template v-if="currentTab==='General'">
          <dl class="row">
            <dt class="col-lg-2">Account Name</dt>
            <dd class="col-lg-4" :title="account.accountName">{{account.accountName}}</dd>
            <dt class="col-lg-2">Account ID</dt>
            <dd class="col-lg-4" :title="account.accountId">{{account.accountId}}</dd>
            <dt class="col-lg-2">Account Email</dt>
            <dd class="col-lg-4" :title="account.email">{{account.email}}</dd>
            <dt class="col-lg-2">Cost Center ID</dt>
            <dd class="col-lg-4" :title="account.costCenterId">{{!account.costCenterId ? "-" : account.costCenterId}}</dd>
            <dt class="col-lg-2">Provider</dt>
            <dd class="col-lg-4" :title="account.provider">{{!account.provider ? "-" : account.provider}}</dd>
            <dt class="col-lg-2">Regions</dt>
            <dd class="col-lg-4">{{!account.regions ? "-" : account.regions.map((region)=> region.name).join(", ")}}</dd>
            <dt class="col-lg-2">Service ID</dt>
            <dd class="col-lg-4" :title="parseComponentIdValue(account.components,false)">
              {{parseComponentIdValue(account.components)}}
              <div>
                <button class="btn-link" style="padding: 0" v-if="showMoreComponentsId!==null" @click="()=>showMoreComponentsId=!showMoreComponentsId">{{showMoreComponentsId ? "show more": "show less"}}</button>
              </div>
            </dd>
            <dt class="col-lg-2">Service Name</dt>
            <dd class="col-lg-4" :title="parseComponentValue(account.components,false)">
              {{parseComponentValue(account.components)}}
              <div>
                <button class="btn-link" style="padding: 0" v-if="showMoreComponents!==null" @click="()=>showMoreComponents=!showMoreComponents">{{showMoreComponents ? "show more": "show less"}}</button>
              </div>
            </dd>
            <dt class="col-lg-2">Monikers</dt>
            <dd class="col-lg-4" :title="account.monikers">
              {{parseMonikerValue(account.components)}}
              <div>
                <button class="btn-link" style="padding: 0" v-if="showMoreMonikers!==null" @click="()=>showMoreMonikers=!showMoreMonikers">{{showMoreMonikers ? "show more": "show less"}}</button>
              </div>
            </dd>
          </dl>
        </template>
        <template v-if="currentTab==='Ownership'">
          <div class="row">
            <div class="col-md-12">
              <button class="btn tab-btn" :class="{ 'tab': true, 'active': currentOwnershipTab === 'ServiceOwners' }"  @click="switchServiceOwnersView">Service Owners</button>
              <button class="btn tab-btn" :class="{ 'tab': true, 'active': currentOwnershipTab === 'AccountOwners' }" @click="switchAccountOwnersView">Account Owners</button>
              <button class="btn tab-btn" :class="{ 'tab': true, 'active': currentOwnershipTab === 'AccountViewers' }" @click="switchAccountViewersView">Account Viewers</button>
              <a v-if="currentOwnershipTab === 'AccountOwners'|| currentOwnershipTab === 'AccountViewers'" class="btn offset-7" :href=sendMailtoGroup()>
                <i class="fas fa-envelope" title="Email Entire Group"/> Email group
              </a>
  <!--            <button class="btn tab-btn" @click="switchOwnersView">Approvers</button>-->
              <v-client-table v-model="this.ownershipTableData" :columns="ownershipTableColumns" :options="ownershipTableOptions">
                <span slot="displayName" slot-scope="props">
                  <a style="color: inherit" :title="props.row.email" :href=getMail(props.row)>{{props.row.displayName ?? "-"}}</a>
                </span>
                <span slot="componentName" slot-scope="props">
                  <a style="cursor: pointer" title="Open Service Overview" @click="openServiceOverview(props.row)">{{props.row.componentName ?? "-"}}</a>
                </span>
              </v-client-table>
            </div>
          </div>
        </template>
        <template v-if="currentTab==='Service'">
          <v-client-table v-model="this.serviceTableData" :columns="serviceTableColumns" :options="serviceTableOptions">
            <span slot="componentId" slot-scope="props">
              <a style="cursor: pointer" title="Open Service Overview" @click="openServiceOverview(props.row)">{{props.row.componentId ?? "-"}}</a>
            </span>
            <span slot="componentName" slot-scope="props">
              <a style="cursor: pointer" :title="props.row.description" @click="openServiceOverview(props.row)">{{props.row.componentName ?? "-"}}</a>
            </span>
            <span slot="totalErrors" slot-scope="props">
              <button title="Open Service Overview" class="btn-link" @click="openServiceOverview(props.row)">
                <i v-if="props.row.totalErrors === 0" class="fas fa-check-circle" style="color: #2bc275;"/>
                <i v-else-if="props.row.totalErrors > 0" class="fas fa-exclamation-circle" style="color: #d74e26;"/>
                <i v-else class="far fa-question-circle" title="Score N/A"/>
                {{props.row.totalErrors ?? "-"}}
              </button>
            </span>
            <span slot="trustScore" slot-scope="props">
              <button title="Open Trust Overview Dashboard" class="btn-link" @click="openTrustOverview(props.row)">
                <i v-if="props.row.trustScore === 0" class="fas fa-check-circle" style="color: #2bc275;"/>
                <i v-else-if="props.row.trustScore > 0" class="fas fa-exclamation-circle" style="color: #d74e26;"/>
                <i v-else class="far fa-question-circle" title="Score N/A"/>
                {{props.row.trustScore ?? "-"}}
              </button>
            </span>
            <span slot="trustOutcomeScore" slot-scope="props">
              <button title="Open Trust Overview Dashboard" class="btn-link" @click="openTrustOverview(props.row)">
                <i v-if="props.row.trustOutcomeScore === 0" class="fas fa-check-circle" style="color: #2bc275;"/>
                <i v-else-if="props.row.trustOutcomeScore > 0" class="fas fa-exclamation-circle" style="color: #d74e26;"/>
                <i v-else class="far fa-question-circle" title="Score N/A"/>
                {{props.row.trustOutcomeScore ?? "-"}}
              </button>
            </span>
          </v-client-table>
        </template>
        <template v-if="currentTab==='Billing'">
          <dl class="row">
            <dt class="col-lg-2">AWS Cost Efficiency</dt>
            <dd class="col-lg-5" title="Efficiency relative to Total Cost and Potential Savings">
              {{parseAccountCostEfficiency()}}
              <a
                  target="_blank"
                  style="font-size: smaller;"
                  :href="'https://looker.autodesk.com/dashboards/15622?&Account+Number='+account.accountId"
              >
                ( High Level Summary of Monthly Cost and Savings <i class="fas fa-external-link-alt"/> )
              </a>
            </dd>
            <dt class="col-lg-2">AWS Total Cost</dt>
            <dd class="col-lg-3" title="Total cost incurred last month">$ {{account?.billing?.TOTAL_COST ?? "-"}}</dd>
            <dt class="col-lg-2">Potential Monthly Savings</dt>
            <dd class="col-lg-5" title="Possible Savings last month">
              $ {{account?.billing?.TOTAL_SAVINGS ?? "-"}}
              <a
                  target="_blank"
                  style=" font-size: smaller;"
                  :href="'https://looker.autodesk.com/dashboards/Cloud_Information::aws_cost_and_savings_aggregate_dashboard?Account+Number='+account.accountId"
              >
                ( Detailed Insights & Savings Opportunities <i class="fas fa-external-link-alt"/> )
              </a>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-lg-2">Cost Center ID</dt>
            <dd class="col-lg-5" :title="account.costCenterId">{{!account.costCenterId ? "-" : account.costCenterId}}</dd>
            <dt class="col-lg-2">Cost Center Owner</dt>
            <dd class="col-lg-3" :title="account?.billing?.COST_CENTER_OWNER">{{account?.billing?.COST_CENTER_OWNER ?? "-"}}</dd>
            <dt class="col-lg-2">Cost Center Division</dt>
            <dd class="col-lg-5" :title="account?.billing?.COST_CENTER_DIVISION">{{account?.billing?.COST_CENTER_DIVISION ?? "-"}}</dd>
          </dl>
          <p v-if="account?.billing?.RECOMMENDATION_DATE"  style="font-size: smaller; position: absolute; right: 0; bottom: 0"> <i class="fas fa-info-circle" /> Recommendation Date: {{account?.billing?.RECOMMENDATION_DATE ?? "-"}}</p>
        </template>
      </div>
      <div v-else class="col-12">
        <div class="loading-container" >
          <loading-spinner  />
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import api from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import util from "@/util";
import LoadingSpinner from "@/components/LoadingSpinner";
import * as C from "../constants.js";

export default {
  name: "accountDetails",
  components: {
    LoadingSpinner
  },
  computed: {
    ...mapGetters(["getAppUser", 
      "getAccounts", 
      "getCacheDataDate",
      "getTokenData",
      "getHasAdminRole"]),
  },
  props: {
    data: {},
    initialTab: String
  },
  data: function () {
    return {
      C,
      isLoading: true,
      currentTab: this.initialTab ?? 'General',
      currentOwnershipTab: 'ServiceOwners',
      showMoreMonikers: null,
      showMoreComponents: null,
      showMoreComponentsId: null,
      maxValueLength : 43 * 2,
      account : {},
      ownershipTableData: {},
      ownershipTableColumns: [],
      ownershipTableOptions: {},
      serviceOwnershipTableData: {},
      serviceOwnershipTableColumns: ["displayName", "title", "organization", "role", "componentName"],
      serviceOwnershipTableOptions: {
        skin: "table users-table table-striped",
        headings: {
          displayName: "Name",
          componentName: "Service Name"
        },
        uniqueKey: "displayName",
        sortable: ["displayName", "componentName"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No users to display",
        },
        headingsTooltips: {
          displayName : "Click to sort. Shift+Click to multi-sort.",
          componentName : "Click to sort. Shift+Click to multi-sort."
        },
        perPage: 10,
        perPageValues: [10,20,50]
      },
      accountOwnershipTableData: {},
      accountOwnershipTableColumns: ["displayName", "email", "title", "organization"],
      accountOwnershipTableOptions: {
        skin: "table users-table table-striped",
        headings: {
          displayName: "Name",
          email: "Email"
        },
        uniqueKey: "email",
        sortable: ["displayName", "email"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No users to display",
        },
        headingsTooltips: {
          displayName : "Click to sort. Shift+Click to multi-sort.",
          email : "Click to sort. Shift+Click to multi-sort."
        },
        perPage: 10,
        perPageValues: [10,20,50]
      },
      serviceTableData:{},
      serviceTableColumns: ["componentId", "componentName", "totalErrors", "trustOutcomeScore", "trustScore"],
      serviceTableOptions: {
        skin: "table users-table table-striped",
        headings: {
          componentId: "Service ID",
          componentName: "Service Name",
          totalErrors: "Service Registry Issues",
          trustOutcomeScore: "Trust Outcomes",
          trustScore: "Total Trust Issues"
        },
        uniqueKey: "componentId",
        sortable: ["componentName", "totalErrors", "trustOutcomeScore", "trustScore"],
        filterable: false,
        filterByColumn: false,
        texts: {
          noResults: "No services to display",
        },
        headingsTooltips: {
          componentName : "Click to sort. Shift+Click to multi-sort.",
          totalErrors: "Number of Issues In Service Registry",
          trustOutcomeScore: "The Trust outcomes only include critical and high vulnerabilities past the due date in production for the service.",
          trustScore : "Trust Scores are calculated based on the number of trust issues found in the service."
        }
      },
    };
  },
  async created() {
    this.isLoading = true
    try {
      let detailsResponse = await api.getAccountDetails(this.data.accountId)
      this.account = detailsResponse.data.data

      if(this.parseComponentValue(this.account.components, false).length > this.maxValueLength){this.showMoreComponents = true}
      if(this.parseComponentIdValue(this.account.components, false).length > this.maxValueLength){this.showMoreComponentsId = true}
      if(this.parseMonikerValue(this.account.components, false).length > this.maxValueLength){this.showMoreMonikers = true}
    } catch (err) {
      this.addAlert(
          util.createAlert(0,
            "Error loading account details. Please try again or contact support.",
            C.ALERT_CONTEXT.DANGER
          )
        );
    }
    if (!this.account.owners) {
      this.account.owners = []
    }
    if (!this.account.viewers) {
      this.account.viewers = []
    }
    if (!this.account.components) {
      this.account.components = []
    }

    this.isLoading = false;

    this.accountOwnershipTableData = this.account.owners
    this.serviceOwnershipTableData = this.createServiceOwnerTableData(this.account.components)
    this.serviceTableData = this.account.components

    this.ownershipTableData = this.serviceOwnershipTableData
    this.ownershipTableColumns = this.serviceOwnershipTableColumns
    this.ownershipTableOptions = this.serviceOwnershipTableOptions
  },
  methods: {
    ...mapMutations(["setAccounts"]),
    ...mapActions(["addAlert"]),
    closeDetailView(){
      this.$router.push({ path: "/" });
    },
    parseDate(date) {
      return util.parseDate(date)
    },
    hasValidAppId(item) {
      return util.hasValidAppId(item)
    },
    createServiceOwnerTableData(services){
      let serviceOwners = []
      services.forEach((service)=>{
        if(service.ownership && 'technicalOwner' in service.ownership && service.ownership.technicalOwner){
          serviceOwners.push({
            componentId: service.componentId,
            componentName: service.componentName,
            role: "Technical Owner",
            displayName: service.ownership.technicalOwner.displayName,
            email: service.ownership.technicalOwner.email,
            title: service.ownership.technicalOwner.title,
            organization: service.ownership.technicalOwner.organization
          })
        }
        if(service.ownership && 'productOwner' in service.ownership && service.ownership.productOwner){
          serviceOwners.push({
            componentId: service.componentId,
            componentName: service.componentName,
            role: "ProductOwner",
            displayName: service.ownership.productOwner.displayName,
            email: service.ownership.productOwner.email,
            title: service.ownership.productOwner.title,
            organization: service.ownership.productOwner.organization
          })
        }
        if(service.ownership && 'businessOwner' in service.ownership && service.ownership.businessOwner){
          serviceOwners.push({
            componentId: service.componentId,
            componentName: service.componentName,
            role: "Business Owner",
            displayName: service.ownership.businessOwner.displayName,
            email: service.ownership.businessOwner.email,
            title: service.ownership.businessOwner.title,
            organization: service.ownership.businessOwner.organization
          })
        }
        if(service.ownership && 'engineeringManager' in service.ownership && service.ownership.engineeringManager){
          serviceOwners.push({
            componentId: service.componentId,
            componentName: service.componentName,
            role: "Engineering Manager",
            displayName: service.ownership.engineeringManager.displayName,
            email: service.ownership.engineeringManager.email,
            title: service.ownership.engineeringManager.title,
            organization: service.ownership.engineeringManager.organization
          })
        }
      })
      return serviceOwners
    },
    parseComponentValue(components, trim){
      let componentString = "-";
      let trimToggle = trim ? trim : this.showMoreComponents
      if(components && components.length>0){
        componentString = components.map((component)=> component.componentName).join(", ")
        var trimmedString = componentString.length > this.maxValueLength ? componentString.substring(0, this.maxValueLength - 3) + "…" : componentString;
      }
      return trimToggle ? trimmedString : componentString
    },
    parseComponentIdValue(components, trim){
      let componentString = "-";
      let trimToggle = trim ? trim : this.showMoreComponentsId
      if(components && components.length>0){
        componentString = components.map((component)=> component.componentId).join(", ")
        var trimmedString = componentString.length > this.maxValueLength ? componentString.substring(0, this.maxValueLength - 3) + "…" : componentString;
      }
      return trimToggle ? trimmedString : componentString
    },
    parseMonikerValue(services, trim){
      let monikerString = "-";
      let trimToggle = trim ? trim : this.showMoreMonikers
      if(services && services.length>0){
        monikerString = services.map((service)=>service?.monikers?.map((moniker)=>moniker.id).join(", ")).join(", ")
        var trimmedString = monikerString.length > this.maxValueLength ? monikerString.substring(0, this.maxValueLength - 3) + "…" : monikerString;
      }
      return trimToggle ? trimmedString : monikerString
    },
    parseAccountCostEfficiency(){
      const percentage = this.account.billing?.CALC_EFF * 100;
      if(isNaN(percentage)){
        return '-'
      }
      if (percentage % 1 === 0) {
        return percentage.toFixed(0) + "%";
      } else {
        return percentage.toFixed(2) + "%";
      }
    },
    switchServiceOwnersView(){
      this.currentOwnershipTab = 'ServiceOwners'
      this.ownershipTableData = this.serviceOwnershipTableData
      this.ownershipTableColumns = this.serviceOwnershipTableColumns
      this.ownershipTableOptions = this.serviceOwnershipTableOptions
    },
    switchAccountOwnersView(){
      this.currentOwnershipTab = 'AccountOwners'
      this.ownershipTableData = this.account.owners
      this.ownershipTableColumns = this.accountOwnershipTableColumns
      this.ownershipTableOptions = this.accountOwnershipTableOptions
    },
    switchAccountViewersView(){
      this.currentOwnershipTab = 'AccountViewers'
      this.ownershipTableData = this.account.viewers
      this.ownershipTableColumns = this.accountOwnershipTableColumns
      this.ownershipTableOptions = this.accountOwnershipTableOptions
    },
    sendMailtoGroup(){
      switch (this.currentOwnershipTab){
        case 'AccountOwners':
          return `mailto:aws.${this.account.accountId}.owners@autodesk.com`
        case 'AccountViewers':
          return `mailto:aws.${this.account.accountId}.users@autodesk.com`
      }
    },
    changeCurrentTab(tab){
      this.currentTab = tab
      switch (tab) {
        case 'General':
          this.trackEvent(C.ACTION_TYPES.GENERAL_PANE_VIEW, this.$router.currentRoute.path);
          break;
        case 'Ownership':
          this.trackEvent(C.ACTION_TYPES.OWNERSHIP_PANE_VIEW, this.$router.currentRoute.path);
          break;
        case 'Billing':
          this.trackEvent(C.ACTION_TYPES.BILLING_PANE_VIEW, this.$router.currentRoute.path);
          break;
        case 'Service':
          this.trackEvent(C.ACTION_TYPES.SERVICE_PANE_VIEW, this.$router.currentRoute.path);
          break;
      }
    },
    openTrustOverview(item){
      window.open(`https://trustdashboard.autodesk.com/applications/${item.id}/outcomes`, '_blank')
      this.trackEvent(C.ACTION_TYPES.TRUST_LINK, this.$router.currentRoute.path, `service_name=${item.componentName}`);
    },
    openServiceOverview(item){
      window.open(`https://one.autodesk.com/apps/service-registry/service-components?search=${item.componentName}`, '_blank')
    },
    getMail(item){
      return `mailto:${item.email}`
    },
  },
};
</script>

<style scoped>

.loading-container {
  margin-top: 25px;
}
.account-help-text {
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}

.access-link {
  font-weight: bold;
}

.tab-btn{
  border-width: 0.25px;
  border-color: #dee2e6;
  border-bottom-width: 0;
  border-radius: 0;
}

.btn{
  font-size: small;
}

.tab.active {
  background-color: #5f60ff;
  color: white;
}

.nav-item{
  padding: 0;
}

</style>
