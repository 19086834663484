import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import CommercialDashboard from '../views/CommercialDashboard.vue'
import FedrampDashboard from '../views/FedrampDashboard.vue'
import Personalize from '../views/Personalize.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/corporate-dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/commercial-dashboard',
    name: 'commercialDashboard',
    component: CommercialDashboard
  },
  {
    path: '/fedramp-dashboard',
    name: 'fedrampDashboard',
    component: FedrampDashboard
  },
  {
    path: '/personalize',
    name: 'personalize',
    component: Personalize
  },
  {
    path: '*',
    redirect: '/corporate-dashboard'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
